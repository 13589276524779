














import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'

@Component({
	components: {},
	mixins: [],
})
export default class SignupSuccess extends Vue
{
	@Prop({ type: String, required: false }) readonly sessionId!: string;

	async mounted()
	{
		try {
			const url = Store.BaseURL + "/signup/checkout-session/" + this.sessionId
			const response = await axios.get(url, {withCredentials: true});
			
			
			
		} 
		catch (ex) { this.$handleError(ex, 'Error getting session') }
	}


}
