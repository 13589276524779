import { createModule, mutation, action } from "vuex-class-component"
import axios from 'axios'
import router from '../router'
import { ToastProgrammatic as Toast } from 'buefy'
import { BuefyType } from '@/lib/BuefyType'
import User from '@/lib/User.model'
import Evt from '@/lib/Evt'
import { Class } from '@/DTO/ClassKids.dto'

const VuexModule = createModule({
	namespaced: "main",
	strict: true,
})

export class MainStore extends VuexModule
{
	private baseURL = ""
	private loggedInUser:User|null = null
	private classes:Class[] = []
	private selectedClass :any = null

	private selectedKidName = ""
	private stripePubKey = ""

	private vueDeployTime = ""
	private apiDeployTime = ""

	get StripePubKey(): string
	{
		return this.stripePubKey
	}

	get ApiDeployTime():string
	{
		return this.apiDeployTime
	}

	get SelectedClass()
	{
		return this.selectedClass
	}

	get BaseURL() 
	{
		return this.baseURL
	}

	get LoggedInUser() 
	{
		return this.loggedInUser
	}

	get Classes()
	{
		return this.classes
	}

	get SelectedKidName()
	{
		return this.selectedKidName
	}

	get CanClasses()
	{
		return this.loggedInUser?.permissions?.classes
	}

	@mutation SetStripePubKey(key: string)
	{
		this.stripePubKey = key
	}

	@mutation SetApiDeployTime(time: string)
	{
		if(time && this.apiDeployTime && time != this.apiDeployTime)
		{
			debugger
			Evt.App_Refresh.Emit(time)
		}	
		
		this.apiDeployTime = time
	}	

	@mutation SetSelectedKidName(name: string)
	{
		this.selectedKidName = name
	}

	@mutation SetSelectedClass(cls: any)
	{
		this.selectedClass = cls
	}

	@mutation SetUser(user: any)
	{
		this.loggedInUser = user
	}

	@mutation SetClasses(classes: any)
	{
		this.classes = classes
	}

	@mutation clear()
	{
		this.loggedInUser = null
		this.classes = []
		this.selectedClass = null
		this.selectedKidName = ""
	}

	@action async Logout(timedOut:boolean)
	{
		const msg = 'Logged out'
		const type = BuefyType.warning
		const url = '/user/logout/'
		try
		{
			this.clear()
			router.push({name: 'Start'},() => {})

			const response = await axios.post(url, {
				timedOut,
				
			}, 
			{withCredentials: true});

			if (this.LoggedInUser) // prevent toast from appearing multiple times after multiple failed requests
			{
				Toast.open({message:msg, type})
			}
		}
		catch(ex)
		{
			Toast.open({message:"Logout failed", type:'is-danger'})
		}			
	}

	@action async GetClasses()
	{
		try
		{
			const response = await axios.get('/api/classes-kids', { withCredentials: true })
			const classes = response.data
			this.SetClasses(classes)
			
			this.SetApiDeployTime(response.headers["x-deployed"])
			//console.log("deployTime from header:", response.headers["x-deployed"])
		}
		catch (ex) 
		{
			console.log(ex)
			if (ex.response.data.statusCode == 401)
			{
				this.Logout(true)
			}
			else{
				Toast.open({
					message: ex,
					type: 'is-danger'
				})
			}
		}
	}
}


