export const Shape = {
	1:{
		active: "emojione-v1:star",
		inactive: "iwwa:star-o",
	},
	2:{
		active: "emojione:balloon",
		inactive: "emojione-monotone:balloon",
	},
	3:{
		active: "emojione:heart-suit",
		inactive: "emojione-monotone:yellow-heart",
	},
	4:{
		active: "emojione:automobile",
		inactive: "emojione-monotone:automobile",
	},
	5:{
		active: "emojione:alien",
		inactive: "emojione-monotone:alien",
	},
	6:{
		active: "emojione:front-facing-baby-chick",
		inactive: "emojione-monotone:front-facing-baby-chick",
	},
	7:{
		active: "emojione:bird",
		inactive: "emojione-monotone:bird",
	},
	8:{
		active: "emojione:bug",
		inactive: "emojione-monotone:bug",
	},
	9:{
		active: "emojione:camel",
		inactive: "emojione-monotone:camel",
	},
	10:{
		active: "emojione:crayon",
		inactive: "emojione-monotone:crayon",
	},
	11:{
		active: "emojione:cookie",
		inactive: "emojione-monotone:cookie",
	},
	12:{
		active: "emojione:deciduous-tree",
		inactive: "emojione-monotone:deciduous-tree",
	},
	13:{
		active: "emojione:dolphin",
		inactive: "emojione-monotone:dolphin",
	},
	14:{
		active: "emojione:dog-face",
		inactive: "emojione-monotone:dog-face",
	},
	15:{
		active: "emojione:gem-stone",
		inactive: "emojione-monotone:gem-stone",
	},
	16:{
		active: "emojione:hamster-face",
		inactive: "emojione-monotone:hamster-face",
	},
	17:{
		active: "emojione:pencil",
		inactive: "emojione-monotone:pencil",
	},
	18:{
		active: "emojione:rosette",
		inactive: "emojione-monotone:rosette",
	},
	19:{
		active: "emojione:rocket",
		inactive: "emojione-monotone:rocket",
	},
	20:{
		active: "ph:math-operations-bold",
		inactive: "ph:math-operations-bold",
	},
	21:{	
		active: "noto:toothbrush",
		inactive: "noto:toothbrush",
	},
	22:{
		active: "emojione:green-book",
		inactive: "emojione-monotone:green-book",
	},
	23:{
		active: "twemoji:broom",
		inactive: "la:broom",
	},
	24:{
		active: "emojione:bed",
		inactive: "emojione-monotone:bed",
	},
}