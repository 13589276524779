

































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'



@Component(
	{
//		components: { ValidationProvider, ValidationObserver },
		props: {},
	}
)
export default class Dialog extends Vue 
{
	@Prop({ type: Boolean, required: false, default: false }) readonly open!: boolean

	public visible = false
	protected problem = ""
	protected addMode = true


	get actionVerb()
	{
		return this.addMode ? "Add" : "Edit"
	}

	protected mounted()
	{
		this.visible = this.open
	}
	
	public Show(show = true, addMode = true)
	{
		this.visible = show
		this.addMode = addMode
	}

	
	public async focusField(field: any)
	{
		// focus on the first field
		const nextField = field as HTMLElement
		if(nextField)
			nextField.focus()
	}
}
