




































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'

import Evt from '@/lib/Evt'
import { Store } from '@/store'
import { BuefyType } from '@/lib/BuefyType'
import { Sound } from '@/lib/Sound'



@Component({
	components: {

	}
})

export default class DlgStep extends Dialog 
{
	quantity = 1
	note = ""

	kidGoalId = 0
	achieved = 0
	total = 0
	timepicker = { incrementMinutes: 15 }
	moment = new Date()

	sound = new Sound()

	mounted()
	{
		Evt.ShowDlgStep.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.ShowDlgStep.Off(this.Display)
	}


	get action(): string
	{
		return this.quantity >= 0 ? "Add stars" : "Remove stars"
	}

	get message(): string
	{
		return this.quantity >= 0 ? "Added stars successfully" : "Removed stars successfully"
	}

	@Watch('quantity')
	onQuantityChange(n:number, o:number)
	{
		this.problem = ""
	}

	public Display(kidGoalId: number, achieved: number, total: number)
	{
		this.visible = true

		this.kidGoalId = kidGoalId
		this.achieved = achieved
		this.total = total
		this.moment = new Date()
	}

	close()
	{
		this.visible = false
		this.problem = ''
		this.quantity = 1
	}

	async submit(event:any)
	{
		if(this.quantity == 0 && !this.note)
		{
			this.problem = "Quanity is 0 and Note is empty"
			return
		}
		
		if(this.quantity < -this.achieved)
		{
			this.problem = "Cannot make achievement negative"
			return
		}

		if(this.quantity + this.achieved > this.total)
		{
			this.problem = "Achievement cannot exceed total"
			return
		}

		try
		{
			const response = await axios.post('/api/step', 
				{
					kidGoalId: this.kidGoalId,
					quantity: this.quantity,
					note: this.note,
					moment: this.moment,
				},
				{withCredentials : true}
			);
			
			if(response.data.success)
			{
				this.$buefy.toast.open({
						message: this.message,
						type: 'is-success',
					});
				
				if(this.quantity + this.achieved == this.total)
				{
					this.sound.playFinale()
					// 440 is the width of the two left bars, this prevents the confetti from hiding befind the bars
					Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
					Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
					Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
				}
				else if(this.quantity > 0)
				{
					this.sound.playSparkle()	
				}

				this.close()
				Evt.GoalView_Refresh.Emit()
				Evt.BarGoalsRefresh.Emit()
				this.quantity = 1
			}
			else
			{
				this.$buefy.notification.open({
					message: response.data.message,
					position: 'is-top',
					type: BuefyType.danger,
				})
			}
		}
		catch(ex)
		{
			this.$buefy.toast.open({
				message: ex.response.data.message,
				type: 'is-danger',
			});
		}
	}

}

