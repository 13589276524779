








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'

@Component({
	components: {},
	mixins: [],
})
export default class Pager extends Vue
{
	
	@Prop({ type: Number, required: true }) readonly currentPage!: number
	@Prop({ type: Number, required: true }) readonly numPages!: number

	protected selectPage(page: number)
	{
		console.log("selectPage", page)
		if(page < 1 || page > this.numPages)
		{
			console.warn(`Can't navigate to page ${page}`)
			return
		}

		this.$emit("selectPage", page)
	}

}
