






































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'

@Component({
	components: {},
	mixins: [],
})
export default class DlgConfirm extends Dialog
{
	objToArchive = ""
	action = ""
	
	get confirmString()
	{
		return `Are you sure you want to ${this.action.toLowerCase()} this ${this.objToArchive}?`
	}

	mounted()
	{
		Evt.Show_DlgConfirm.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.Show_DlgConfirm.Off(this.Display)
	}

	public Display(objToArchive: string, action="Archive")
	{
		this.objToArchive = objToArchive
		this.action = action
		this.visible = true
	}

	protected close()
	{
		this.visible = false
	}

	protected async submit()
	{
		this.$emit('confirm', this.objToArchive)
		this.close()
	}

}
