



































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios';
import Dialog from '@/components/Dialog.vue'
import { Store } from '@/store'
import { BuefyType } from '@/lib/BuefyType'
import Evt from '@/lib/Evt'
import DlgConfirm from '@/components/DlgConfirm.vue'
import { Role } from '../shared/Permissions'
import Pager from '@/components/Pager.vue'
import { Debounce } from 'vue-debounce-decorator'
import { Rabbit, enc } from 'crypto-js'

@Component({
	components: { DlgConfirm, Pager, },
})
export default class DlgClass extends Dialog 
{
	name = ''
	importFromClass = 0
	classes = [{ id: 0, name: '' }]
	problem = ""
	kids = []
	selectedKids = []
	classId = 0
	page = 1
	itemsPerPage = 10
	numPages = 0
	search = ""
	key = ''

	columns = [
		{
			field: 'firstname',
			label: 'First Name',
		},
		{
			field: 'lastname',
			label: 'Last Name',
		},
	]
	

	async Show(show = true, addMode = true, classId = 0) 
	{
		this.visible = show
		this.addMode = addMode
		this.classId = classId

		// const encKey = Store.LoggedInUser!.school!.EncryptionKey!
		// const schoolClassString = Store.LoggedInUser!.school!.id + '/' + this.classId
		// let e = Rabbit.encrypt(schoolClassString, encKey)
		// console.log( e )
		// let es = e.toString()
		// let d = Rabbit.decrypt(es, encKey)
		// let ds = d.toString( enc.Utf8 )

		if (this.visible) 
		{
			Vue.nextTick(() => {
				// focus on the first field
				const nextField = this.$refs.name as HTMLElement;
				nextField.focus();
			});

			this.refresh()
			if( !this.addMode )
			{
				this.getClass(this.classId)
			}
		}
		else
		{
			this.clear()
		}
	}

	async clear()
	{
		this.name = '';
		this.importFromClass = 0;
		this.classes = [{ id: 0, name: '' }];
		this.problem = ""
		this.kids = []
		this.selectedKids = []
		this.classId = 0
	}

	@Debounce(1000)
	@Watch('search')
	onSearch(n: string, o:string)
	{
		this.page = 1 	// reset page to 1 if filter or search changes
		this.refresh()
	}
	
	selectPage(page: number)
	{
		this.page = page
		this.refresh()
	}

	async refresh()
	{
		try {
			let url = "/user/users"
			url += "?role=" + Role.kid
			url += "&page=" + this.page
			url += "&itemsPerPage=" + this.itemsPerPage
			url += "&sort=lastname"
			if(this.search != "")
				url += "&search=" + this.search			
			
			const response = await axios.get(url, {
				withCredentials: true,
			})
			
			this.page = 1
			this.kids = response.data[0]
			const count = response.data[1]
			this.numPages = Math.ceil( count/this.itemsPerPage )			
		} 
		catch (ex) 
		{
			this.$handleError(ex, "Couldn't load kids")
		}
	}

	async getClass(classId: number)
	{
		try {
			const url = Store.BaseURL + '/api/class/' + classId;
			const response = await axios.get(url, {
				withCredentials: true,
			});

			const cls = response.data
			this.classId = cls.id
			this.name = cls.name
			this.selectedKids = cls.users
			this.key = cls.Key
		} 
		catch (ex) { this.$handleError(ex, "Couldn't load kids") }
	}

	close() 
	{
		this.search = ""
		this.page = 1

		this.Show(false)
	}

	async submit() 
	{
		const url = Store.BaseURL + '/api/class';
		try 
		{
			let apiCall = axios.put
			if(this.addMode)
				apiCall = axios.post

			const response = await apiCall(
				url,
				{
					id: this.classId,
					name: this.name,
					class: this.importFromClass,
					kids: this.selectedKids.map( ({id}) => {return {id} })
				},
				{ withCredentials: true }
			)

			this.$buefy.toast.open({
				message: 'Success!',
				type: 'is-success',
			})

			this.name = ''
			this.importFromClass = 0

			this.Show(false)

			Evt.BarClassKid_Refresh.Emit(response.data.o.id)
		} 
		catch (ex)
		{
			this.problem = ex.response?.data?.message
			this.$handleError(ex, "Error saving class")
		}
	}

	archive()
	{
		Evt.Show_DlgConfirm.Emit("class")
	}

	async confirmArchive()
	{
		try
		{
			const url = '/api/class/' + this.classId
			const response = await axios.delete(url, {withCredentials: true})

			Evt.BarClassKid_Refresh.Emit()
			Evt.BarGoalsRefresh.Emit()
			Evt.GoalView_Refresh.Emit()
			Evt.UnselectClassKid.Emit()

			this.$buefy.notification.open({
					message: `Class successfully archived`,
					position: 'is-top',
					type: 'is-success',
			})			
			this.close()
		} 
		catch (ex) { this.$handleError(ex, 'Error archiving class') }
	}

	showInviteLink()
	{
		Evt.Show_DigInviteLink.Emit(this.key)
	}
}
