	export class Usr {
		id: number = 0
		firstname?: string
		lastname?: string
	}

	export class Kidgoal {
		id: number = 0
		achieved: number = 0
		usr: Usr = {id:0}
	}

	export class GoalKidsDTO {
		id = 0
		name = ""
		details = ""
		total = 0
		prize = ""
		prize_url = ""
		prize_image = ""
		shape = 0
		start?: string
		end?: string

		kidgoals:Kidgoal[] = []
		
		constructor(id = 0, name = "", details = "", total = 0)
		{
			this.id = id
			this.name = name
			this.details = details
			this.total = total
			this.kidgoals = []
		}
	}



