export enum Role
{
	kid         = 10,
	parentInd   = 20,	//independent parent
	parentClass = 21,
	teacher     = 30,
	admin       = 40	//school-admin
}
export class Permission
{
	steps      = false
	goals      = false
	kids       = false
	parents    = false
	teachers   = false
	classGoals = false
	classes    = false	//add/edit/archive class
	admin		= false	// add/edit/archive school admin
	subscribe   = false
}


export let DefaultPermissions: { [key in Role]?: Permission } = 
{
	[Role.kid]         : {steps: false, goals: false, kids: false, parents: false, teachers: false, classGoals: false, classes:false, admin: false, subscribe: false},
	[Role.parentClass] : {steps: false, goals: false, kids: false, parents: false, teachers: false, classGoals: false, classes:false, admin: false, subscribe: false},
	[Role.parentInd]   : {steps: true,  goals: true,  kids: true,  parents: true,  teachers: false, classGoals: false, classes:false, admin: false, subscribe: false},
	[Role.teacher]     : {steps: true,  goals: true,  kids: true,  parents: true,  teachers: false, classGoals: true,  classes:false, admin: false, subscribe: false},
	[Role.admin]      :  {steps: true,  goals: true,  kids: true,  parents: true,  teachers: true , classGoals: true,  classes:true,  admin: true,  subscribe: true},
}

export let RoleString: { [key in Role]?: string } = 
{
	[Role.kid]         : "Kid",
	[Role.parentClass] : "Parent",	// both parents show the same string as they aren't used together
	[Role.parentInd]   : "Parent",
	[Role.teacher]     : "Teacher",
	[Role.admin]       : "Admin",
}

export let PlanString: { [key in Role]?: string } = 
{
	[Role.kid]         : "Kid",
	[Role.parentClass] : "Parent",	// both parents show the same string as they aren't used together
	[Role.parentInd]   : "Parent",
	[Role.teacher]     : "Teacher",
	[Role.admin]       : "School",
}