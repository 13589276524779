

































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Problem from '@/components/Problem.vue'
import { Store } from '@/store'
import { PlanString, Role } from '../shared/Permissions'
import User from '@/lib/User.model';
import router from '@/router'
import Logo from '@/components/Logo.vue'

@Component({
	components: {
		Problem, Logo
	}
})

export default class Signup extends Vue
{
	@Prop({ type: Number, required: false, default: Role.parentInd }) readonly role!: Role
	@Prop({ type: String, required: false }) readonly targetClass!: string

	// email        = "a@naveed.net"
	// username 	   = "a"
	// firstname    = "a"
	// lastname     = "a"
	// password     = "a"
	// confirmation = "a"

	email        = ""
	username 	 = ""
	firstname    = ""
	lastname     = ""
	password     = ""
	confirmation = ""
	school       = ""
	refCode	     = ""

	problem 	 = ""
	Role         = Role
	//role:Role    = Role.parentInd

	tosAccepted = false
	router = router

	mounted()
	{
		if(this.$route.query.r)
		{
			this.refCode = this.$route.query.r.toString()
		}
	}

	get origin(): string
	{
		let org = window.location.origin
		console.log(window.location.port)
		if(window.location.port == "8080")
			org = `{window.location.protocol}://{window.location.host}:3001`
		return org
	}

	get urlTOS(): string
	{
		const landingOrigin = origin.replace('app', 'www')
		return `${landingOrigin}/terms.html`
	}

	get urlPrivacy(): string
	{
		const landingOrigin = origin.replace('app', 'www')
		return `${landingOrigin}/privacy.html`
	}

	get plan(): string|undefined
	{
		return PlanString[ this.role ]
	}

	protected async signup()
	{
		const url = Store.BaseURL + '/user/signup'
		let failureMessage = ''
		let createdUser:User|null = null

		try{
			const response = await axios.post(url, {
				email        : this.email.trim(),
				username     : this.username.trim(),
				firstname    : this.firstname.trim(),
				lastname     : this.lastname.trim(),
				password     : this.password,
				role         : this.role,
				schoolName   : this.school.trim(),
				screen       : this.$screen(),
				refCode      : this.refCode,
				//encTargetClass : this.targetClass,
			},
			{withCredentials : true}
			);
	
			createdUser = response.data.o
			Store.SetUser(createdUser)

			this.password = ""
			this.confirmation = ""
			
			if(createdUser!.role == Role.parentClass)	// Even if signing up as IndependentParent, but signing up using an invite link, the role for the created user will be ClassParent
				this.$router.push( {name: 'parent', query:this.$route.query })
			else
				this.$router.push( {name: 'kids', query:this.$route.query })

			
		}
		catch(ex)
		{
			this.problem = ex.response?.data?.message
			this.$buefy.toast.open({
				message: "Sign up failed",
				type: 'is-danger',
			});
		}
	}


}

