var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"dialog-background"},[_c('div',{staticClass:"dialog DlgResetPassword"},[_c('div',{staticClass:"header"},[_vm._v("Reset password for "+_vm._s(_vm.username))]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"New password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Confirm new password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","name":"confirm"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})],1)]}}],null,true)}),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"left"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v("Save")]),_c('b-button',{attrs:{"type":"is-light"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1),_c('div',{staticClass:"right"})])],1)]}}],null,false,369850158)}),(_vm.problem)?_c('div',{staticClass:"problem"},[_vm._v(_vm._s(_vm.problem))]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }