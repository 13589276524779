





































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt, { DlgUserMode } from '@/lib/Evt'
import axios from 'axios'
import {Role, RoleString} from '../shared/Permissions'
import NavBar from '@/components/NavBar.vue'
import { Debounce } from 'vue-debounce-decorator'
import Pager from '@/components/Pager.vue'
import DlgUser from '@/components/DlgUser.vue'

enum ActiveOptions  
{
	Active = 1,
	Archived = 2,
	All = 3,
}

@Component({
	components: { NavBar, Pager, DlgUser },
	mixins: [],
})
export default class Users extends Vue
{
	ActiveOptions = ActiveOptions
	RoleString = RoleString
	Role = Role

	data = []
	selectedRole:Role|null = null
	selectedActive = ActiveOptions.Active

	search = ""
	sort = "username"
	down = false
	page = 1
	numPages = 0
	count = 0
	itemsPerPage = 10
	calculatedItemsPerPage = 10

	permissions = Store.LoggedInUser!.permissions	// logged in user permissions, not the user being added/created

	ActiveString: { [key in ActiveOptions]?: string } = 
	{
		[ActiveOptions.Active]	 : "Active users",
		[ActiveOptions.Archived] : "Archived users",
		[ActiveOptions.All]		 : "All users",
	}

	get selectedActiveString()
	{
		return this.ActiveString[this.selectedActive]
	}

	get selectedRoleString()
	{
		if(this.selectedRole)
			return RoleString[this.selectedRole]
		else 
			return "All user role"	// ending 's' is added in the template
	}

	async mounted()
	{
		this.calculatedItemsPerPage = this.itemsPerPage = Math.floor( (window.innerHeight - 290)/40 )
		
		this.refresh()
		Evt.Users_Refresh.On(this.refresh)
	}

	beforeDestroy()
	{
		Evt.Users_Refresh.Off(this.refresh)
	}

	async refresh()
	{
		try 
		{
			// if(Store.LoggedInUser!.role == Role.parentInd)
			// 	this.selectedRole = Role.kid
			
			let url = "/user/users"

			if(this.down)
				url += "?sort=~" + this.sort
			else
				url += "?sort=" + this.sort

			if(this.selectedRole)
				url += "&role=" + this.selectedRole

			if(this.selectedActive != ActiveOptions.All)
				url += "&archived=" + (this.selectedActive == ActiveOptions.Archived)

			if(this.search != "")
				url += "&search=" + this.search

			url += "&page=" + this.page
			url += "&itemsPerPage=" + this.itemsPerPage
			
			const response = await axios.get(url, {withCredentials: true});
			this.data = response.data[0]
			this.count = response.data[1]
			this.numPages = Math.ceil( this.count/this.itemsPerPage )
			
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	@Debounce(200)
	selectRole(role: Role|null)
	{
		this.page = 1 	// reset page to 1 if filter or search changes
		this.selectedRole = role
		this.refresh()
	}
	
	@Debounce(200)
	selectActive(option:number)
	{
		this.page = 1 	// reset page to 1 if filter or search changes
		this.selectedActive = option
		this.refresh()
	}

	@Debounce(1000)
	@Watch('search')
	onSearch(n: string, o:string)
	{
		this.page = 1 	// reset page to 1 if filter or search changes
		this.refresh()
	}

	protected doSort(column: string)
	{
		if(column == this.sort)
			this.down = !this.down
		else
			this.sort = column
		this.refresh()
	}

	protected selectPage(page: number)
	{
		this.page = page
		this.refresh()
	}

	protected editUser(id: number)
	{
		Evt.ShowDlgUser.Emit(DlgUserMode.Edit, id, 0)
	}
	
	protected addUser()
	{
		let classId = 0

		if(Store.LoggedInUser!.classes!.length == 1)
			classId = Store.LoggedInUser!.classes[0].id

		Evt.ShowDlgUser.Emit(DlgUserMode.Add, 0, classId)
	}
}
