





















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'

import DlgClass from '@/components/DlgClass.vue'
import Evt, { DlgUserMode } from '@/lib/Evt'
import router from '@/router';
import { Store } from '@/store'
import Empty from '@/components/Empty.vue'
import {Role} from '../shared/Permissions'
import {Kid} from '@/DTO/KidClass.dto'

@Component({
	components: { DlgClass, Empty },
	mixins: [],
})
export default class BarClassKid extends Vue 
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number	//only needed to pass it down to SubBarClassGoals

	permissions = Store.LoggedInUser!.permissions
	kids:Kid[] = []
	left = '0'
	visible = true

	
	async mounted()
	{
		Evt.BarParent_Refresh.On(this.refresh)
		Evt.Show_BarParent.On(this.show)
		await this.refresh()
	}

	beforeDestroy()
	{
		Evt.BarParent_Refresh.Off(this.refresh)
		Evt.Show_BarParent.Off(this.show)
	}

	show(visible: boolean)
	{
		this.visible = visible

		if(visible)
		{
			this.left = '0'
		}
		else
		{
			this.left = '-220px'	//TODO use variables
			Evt.Show_BarGoal.Emit(false)	/// Hide bargoal too
		}
	}	

	selectClass(kidId:number, clsId:number)
	{
		router.push({name: 'parent', params:{kidId:kidId.toString(), clsId:clsId.toString(), }},() => {})
		Evt.Show_BarGoal.Emit(true)
	}

	selectKid(kidId: number)
	{
		const kid = this.kids.find(i => i.id == kidId)
		if(!kid)
		{
			console.log("kid not found")
			Store.SetSelectedKidName('')
			return
		}

		Store.SetSelectedKidName(`${kid.firstname} ${kid.lastname}`)
		router.push({name: 'parent', params:{ clsId:this.clsId.toString() }},() => {})
	}

	async refresh()
	{
		const url = Store.BaseURL + '/api/parents-kids'

		try {
			const response = await axios.get(url, {withCredentials: true});
			this.kids = response.data
			
			if(this.clsId && this.kidId)
				this.selectClass(this.kidId, this.clsId)
		} 
		catch (ex)
		{
			this.$handleError(ex, "Couldn't load kids")
		}
	}

	addKid()
	{
		Evt.HideTooltip.Emit('tip-click-here')
		Evt.ShowDlgUser.Emit(DlgUserMode.Add, 0, this.clsId)
	}
}
