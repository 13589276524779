import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import axios from 'axios'
import router from '../router'
import { ToastProgrammatic as Toast } from 'buefy'

import { extractVuexModule, createProxy } from 'vuex-class-component'
import { MainStore } from './MainStore'
//import { ClassStore } from './ClassStore'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
	key: 'vuex', // The key to store the state on in the storage provider.
	storage: window.localStorage, // or window.sessionStorage or localForage
	// Function that passes the state and returns the state with only the objects you want to store.
	// reducer: (state: any) => ({
	// 	LoggedInUser: state.LoggedInUser
	// }),
	// Function that passes a mutation and lets you decide if it should update the state in localStorage.
	// filter: mutation => (true)
})



const store = new Vuex.Store({
	modules: {
		...extractVuexModule(MainStore),
		//ClassStore
	},
	// state: {
	// 	BaseURL: "http://localhost:3000",
	// 	LoggedInUser: null,
	// 	Classes: []
	// },
	// getters: {
	// 	CurrentUser: state =>
	// 	{
	// 		return state.LoggedInUser
	// 	}
	// },
	// mutations: {
	// 	SET_user: (state, payload) =>
	// 	{
	// 		state.LoggedInUser = payload
	// 	},
	// 	UNSET_user: (state, payload) =>
	// 	{
	// 		state.LoggedInUser = null
	// 	},
	// 	SET_CLASSES: (state, payload) =>
	// 	{
	// 		state.Classes = payload
	// 	}
	// },
	// actions: {
	// 	set_user: (context, payload) =>
	// 	{
	// 		context.commit("SET_user", payload)
	// 	},
	// 	unset_user: (context, payload) =>
	// 	{
	// 		context.commit("UNSET_user", payload)
	// 	},
	// 	async GetClasses({ commit, state }, payload){
	// 		const url = state.BaseURL + '/api/classes-kids'

	// 		try
	// 		{
	// 			const response = await axios.get(url, { withCredentials: true });
	// 			const classes = response.data
	// 			commit("SET_CLASSES", classes)
	// 		}
	// 		catch (ex) 
	// 		{
	// 			console.log(ex)
	// 			if (ex.response.data.statusCode == 401){
	// 				router.push('/')
	// 				Toast.open({message:"Couldn't get classes", type:'is-warning'})
	// 			}
	// 			else{
	// 				Toast.open({
	// 					message: ex,
	// 					type: 'is-danger'
	// 				});	
	// 			}
	// 		}
	// 	},
		
	// },
	plugins: [vuexLocalStorage.plugin]
});

//export default store
const Store = createProxy(store, MainStore)


// Creating proxies.
// export const vxm = {
// 	User,
// }

export {
	Store
}