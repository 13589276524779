



























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import { integer } from 'vee-validate/dist/rules'

@Component({
	components: {},
	mixins: [],
})
export default class ToolTip extends Vue
{
	@Prop({ type: String, required: true }) readonly name!: string
	@Prop({ type: String, required: false, default: 'left' }) readonly direction!: string
	@Prop({ type: Boolean, required: false, default: false })  readonly show!:   boolean

	@Prop({ type: String, required: false })                  readonly left!:   string
	@Prop({ type: String, required: false })                  readonly top!:   string
	@Prop({ type: String, required: false })                  readonly right!:   string
	@Prop({ type: String, required: false })                  readonly bottom!:   string

	@Prop({ type: String, required: false })                  readonly message!:   string

	visible = false
	pos = {left: '', top: '', right: '', bottom: ''}
	dontShowAgain = false
	dir = this.direction

	mounted()
	{
		this.visible = this.show
		Evt.HideTooltip.On(this.hide)
		Evt.ShowTooltip.On(this.display)
	}

	beforeDestroy()
	{
		Evt.HideTooltip.Off(this.hide)
		Evt.ShowTooltip.Off(this.display)
	}

	hide(name: string, dontShowAgain = false)
	{
		if(this.name == name 
			|| !name)	// hide all tooltips if name not specified
			this.visible = false
		this.dontShowAgain = dontShowAgain
	}

	display(name: string, selector = '')
	{
		if(this.name != name)
			return

		if(this.dontShowAgain)	// hits only if name matches
			return

		Evt.HideTooltip.Emit()	// hide other tooltips

		this.visible = true

		Vue.nextTick(() => 
		{
			let tooltipRect = new DOMRect()
			const tooltip = this.$refs.tooltip as HTMLElement
			if(tooltip)
			{
				tooltipRect = tooltip.getBoundingClientRect()
			}

			if(selector)
			{
				const element = document.querySelector(selector)
				if(element)
				{
					this.dir = this.direction
					this.setPosition(this.dir, element, tooltipRect)

					// Vue.nextTick(() => 
					// {
					// 	const ttRect = tooltip.getBoundingClientRect()
					// 	if(this.dir == 'right' && ttRect.right > window.innerWidth)
					// 	{
					// 		this.dir = 'bottom'
					// 		this.setPosition(this.dir, element, tooltipRect)
					// 	}
					// })
				}
			}	
		})
	}

	setPosition(direction: string, element: Element, tooltipRect: DOMRect)
	{
		let rect = element.getBoundingClientRect()
		switch(direction)
		{
			case 'right':
				this.pos.top = rect.y + rect.height/2 - tooltipRect.height/2 + "px"
				this.pos.left = rect.x + rect.width + "px"
			break
			case 'top':
				this.pos.top = rect.y - tooltipRect.height - 32 + "px"
				this.pos.left = rect.x + "px"
			break
			case 'left':
				this.pos.left = rect.x - tooltipRect.width - 8 + "px"
				this.pos.top = rect.y + rect.height/2 - tooltipRect.height/2 + "px"
			break
			case 'bottom':
				this.pos.top = rect.bottom + 16 + "px"
				this.pos.left = rect.x + "px"
			break
		}
	}
}
