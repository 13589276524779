



































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
//import {mapState} from 'vuex'
import Evt, { DlgUserMode } from '@/lib/Evt'
import axios from 'axios'
import Avatar from '@/components/Avatar.vue'
import { Store } from '@/store'
import { Role } from '../shared/Permissions'
import { BuefyType } from '@/lib/BuefyType'
import { IdleTimer } from '@/lib/IdleTimer'
import Logo from '@/components/Logo.vue'

@Component({
	components: { Avatar, Logo },
	props: {},
	mixins: [],
})
export default class NavBar extends Vue
{
	@Prop({ type: Function, required: false }) readonly barEvent!: any

	LoggedInUser = Store.LoggedInUser
	idleTimeoutSeconds = this.LoggedInUser!.inactivityMinutes * 60

	secondsLeftBeforeLogout = 0
	showLogoutWarning = false

	idleTimer = new IdleTimer(this.onIdleTimeout, this.onRemind, this.idleTimeoutSeconds, [40], 1)

	get homelink()
	{
		if(this.LoggedInUser!.role == Role.kid)
			return "kid"
		else if(this.LoggedInUser!.role == Role.admin ||
				this.LoggedInUser!.role == Role.teacher ||
				this.LoggedInUser!.role == Role.parentInd
		)
			return "kids"
		else if(this.LoggedInUser!.role == Role.parentClass)
			return 'parent'
	}

	get referralsVisible()
	{
		return [Role.teacher, Role.admin].includes(this.LoggedInUser!.role!)
	}

	get usersVisible()
	{
		if(this.LoggedInUser!.role == Role.kid || 
		   this.LoggedInUser!.role == Role.parentClass )
		   return false

		return this.LoggedInUser!.permissions.kids || 
				this.LoggedInUser!.permissions.parents || 
				this.LoggedInUser!.permissions.teachers ||
				this.LoggedInUser!.permissions.admin 
	}


	get manageBillingVisible(): boolean
	{
		return this.LoggedInUser?.school?.nextBillDate != null	
	}

	get displayTimeLeftToLogout()
	{
		//if(this.idleTimer && this.idleTimer.display)
			return this.idleTimer.display
	}

	async portal()
	{
		const response = await axios.post('', null, {withCredentials : true});
	}

	created() 
	{
		window.addEventListener("resize", this.resized)
	}
	
	destroyed() 
	{
  		window.removeEventListener("resize", this.resized)
	}

	resized(e: Event)
	{
		if( !this.$isMobileView() )
		{
			Evt.Show_BarClassKid.Emit(true)
			Evt.Show_BarGoal.Emit(true)
			Evt.Show_BarParent.Emit(true)
		}	
		else{

		}
	}

	mounted()
	{
		Vue.nextTick(() => {
			this.idleTimer.init()
		})
	}
	
	profile()
	{
		Evt.ShowDlgUser.Emit(DlgUserMode.Profile, Store.LoggedInUser!.id, 0)
	}

	referrals()
	{
		Evt.Show_DlgReferrals.Emit()
	}

	async logout()
	{
		this.idleTimer.destroy()
		Store.Logout(false)
	}

	onIdleTimeout() 
	{
		console.log(new Date(), 'idleTimeout')
		this.logout()
	}
	
	onRemind() 
	{
		this.showLogoutWarning = true
	}

	async resetLogoutWarning()
	{
		this.showLogoutWarning = false
		this.idleTimer.resetTimers()
		try {
			const url='/api/health-check'
			const response = await axios.get(url, {withCredentials: true})	// reset cookie too
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	showBar()
	{
		this.barEvent.Emit(true)
	}
}
