









































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import axios from 'axios'
import DlgLogin	from '@/components/DlgLogin.vue'
import Logo from '@/components/Logo.vue'
import DlgResetPassword from '@/components/DlgResetPassword.vue'
import { Store } from '@/store'
import Dialog from '@/components/Dialog.vue';
import router from '@/router';
import {Role} from "../shared/Permissions"

@Component({
	components: { 
		DlgLogin, 
		DlgResetPassword,
		Logo,
		 },
})
export default class Start extends Vue 
{
	protected token:string = ""
	dlgLogin:Dialog|null = null
	mainVisible = true
	
	mounted()
	{
		if(this.action == 'signup')
		{
			router.push( {name: 'Signup', params:{ role: Role.parentInd.toString(), targetClass: this.targetClass}} )
		}

		this.dlgLogin = this.$refs.dlgLogin as Dialog
		this.mainVisible = !this.action

		if(Store.LoggedInUser)
		{
			if(Store.LoggedInUser.role == Role.kid)
				router.push({name: 'kid',  query:this.$route.query },() => {})
			else
				router.push({name: 'kids', query:this.$route.query },() => {})
		}
	}
	
	get action()
	{
		return this.$route.query.action
	}

	get targetClass(): string	// allow users to sign up to this class
	{
		const cls = this.$route.query.class
		if(cls)
			return cls.toString()
		else
			return ''
	}

	@Watch('$route.path')
	onRouteChanged(n: string, o: string) 
	{
		console.log(n)
	}

	showLogin(){
		let dlg = this.$refs.dlgLogin as DlgLogin
		dlg.Show()
		this.mainVisible = !dlg.visible
	}

	loginClosed()
	{
		this.mainVisible = true
	}
}
