


































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'

@Component({
	components: {},
	mixins: [],
})
export default class DlgFeedback extends Dialog
{
	subject = ""
	details = ""

	mounted()
	{
		Evt.Show_DlgFeedback.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.Show_DlgFeedback.Off(this.Display)
	}

	public Display()
	{
		this.clear()
		this.visible = true
	}

	protected close()
	{
		this.visible = false
	}

	clear()
	{
		this.subject = ""
		this.details = ""
	}

	protected async submit()
	{
		try {
			const url='/api/feedback'
			const response = await axios.post(url,
				{
					subject: this.subject,
					details: this.details,
					url: window.location.href
				},
				{withCredentials: true});
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
		this.close()
	}

}
