export class Kidgoal // Input from front-end
{
	id:         number = 0

	achieved:   number = 0
	asin:       string = ''
 	clsId:	 	number = 0
	end:     	string | Date | null = null	// transmitted as string over REST
	prize?:     string = ''
	prize_url?: string = ''
    goalId:     number = 0
    shape:      number = 1	// needs to be string for it to work with radio button
    start:    	string | Date = ''	// transmitted as string over REST
    usrId:      null|number = 0

	// sort in descending order for start dates
	static Sort(a:Kidgoal, b:Kidgoal): number
	{
		if(a.start < b.start)
			return 1
		if(a.start > b.start)
			return -1

		return 0
	}
}


export class Goal
{
    id:        number

	classId:   number
	kidId:     number
    classGoal: boolean
    details:   string
    kidgoals:  Kidgoal []
    name:      string
    total:     number

	constructor()
	{
		this.id = 0
		this.classGoal = false
		this.classId = 0
		this.details = ''
		this.kidId = 0
		this.kidgoals = []
		this.name = ''
		this.total = 0
	}
}
