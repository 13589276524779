




































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
	components: {},
	mixins: [],
})
export default class Empty extends Vue
{
	@Prop({ type: String, required: false, default: '' }) readonly message!: string;


}
