



































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import { loadStripe, Stripe } from '@stripe/stripe-js';
import axios from 'axios'

import { Role } from '../shared/Permissions';
import router from '@/router'
import Logo from '@/components/Logo.vue'

@Component({
	components: { Logo },
	mixins: [],
})
export default class SelectPlan extends Vue
{
	protected data = []
	protected stripe: Stripe | null = null

	Role = Role	// make available to vue template
	

	selectPlan(role:Role)
	{
		let intRole = (role as number).toString()
		router.push( {name: 'Signup', params:{ role: intRole }, query:this.$route.query} )
	}

}
