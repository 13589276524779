














































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt, { DlgCycleMode } from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { BuefyType } from '@/lib/BuefyType'
import { DateTime } from "luxon"
import { Goal, Kidgoal } from '../shared/Goal.dto'
import router from '@/router'
import { Amazon } from '@/DTO/Amazon.dto'
import { Shape } from '@/lib/Shape'
import DlgConfirm from '@/components/DlgConfirm'
import { Class, User } from '@/DTO/ClassKids.dto'

@Component({
	components: { DlgConfirm },
	mixins: [],
})
export default class DlgCycle extends Dialog
{
	shapes = Shape
	startdate:Date|null = new Date()
	enddate:  Date|null = null

	kidgoal = new Kidgoal()
	goal: Goal|undefined
	mode = DlgCycleMode.AddCycle
	kids:User[] = []
	classId:number = 0
	kidId:number = 0
	orgStart = ''

	blankAmazonData:Amazon = {asin:undefined, image:undefined}
	amazonData:Amazon =  this.blankAmazonData
	rgxASIN = /\/([A-Z0-9]{10})\/|\/([A-Z0-9]{10})[^-A-Z0-9]/

	get kidDisabled(): boolean
	{
		return    this.mode == DlgCycleMode.AddGoal ||
				( this.mode == DlgCycleMode.AddCycle && this.goal!.classGoal ) ||
				( this.mode == DlgCycleMode.EditCycle )
	}

	get submitVerb(): string
	{
		switch(this.mode)
		{
			case DlgCycleMode.AddGoal: return "Add goal"
			case DlgCycleMode.AddCycle: return "Start cycle"
			case DlgCycleMode.EditCycle: return "Update"
			default: return "Save"
		}
	}

	get showClass()
	{
		return Store.LoggedInUser!.permissions.classes
	}	
	
	mounted()
	{
		Evt.Show_DlgCycle.On(this.Display)
	}
	
	beforeDestroy()
	{
		Evt.Show_DlgCycle.Off(this.Display)
	}

	@Watch('kidId')
	onKidChanged(n: number, o: number)
	{
		if(n)	// reset dates when changing from whole Class to kid, to prevent chaanging individual kid's dates on a classGoal cycle
		{
			this.startdate = DateTime.fromISO( this.kidgoal.start.toString() ).toJSDate()
			this.enddate   = this.kidgoal.end ? DateTime.fromISO( this.kidgoal.end.toString() ).toJSDate() : null			
		}
	}

	refreshKidList()
	{
		const wholeClass:User = {id: 0, username:"", firstname:"Whole class", lastname: ""}
		const allKids:User = {id: 0, username:"", firstname:"All kids", lastname: ""}

		if(this.showClass)
			this.kids = [wholeClass]
		else
			this.kids = [allKids]

		const cls = Store.Classes.find( (o:Class) => o.id == this.classId) as unknown as Class
		if(cls)
			this.kids.push(...cls.users)	//use push with spread, instead of concat, as concat gets an observer instead of actual values	
	}

	async Display(mode: DlgCycleMode, kidgoal:Kidgoal, goal:Goal): Promise<void>
	{
		this.problem = ""
		this.kidgoal = kidgoal
		this.goal = goal
		this.mode = mode
		this.visible = true
		this.classId = kidgoal.clsId
		this.amazonData = this.blankAmazonData

		this.kidId = goal.classGoal ? 0 : goal.kidId // If it's a classGoal, kidId should be 0 when sent to the server

		await Store.GetClasses()
		this.refreshKidList()

		if(mode == DlgCycleMode.EditCycle)
		{
			this.addMode = false
			this.startdate = DateTime.fromISO( kidgoal.start.toString() ).toJSDate()
			this.enddate   = kidgoal.end ? DateTime.fromISO( kidgoal.end.toString() ).toJSDate() : null
			this.orgStart = DateTime.fromJSDate( this.startdate! ).toISODate()
			this.refreshImage()
		}
		else
		{
			this.startdate = new Date()
			this.enddate = null
		}
	}

	close()
	{
		this.visible = false
	}

	async submit()
	{
		this.kidgoal.start = DateTime.fromJSDate( this.startdate! ).toISODate()
		this.kidgoal.end   = this.enddate ? DateTime.fromJSDate( this.enddate ).toISODate() : null

		if(this.mode == DlgCycleMode.AddGoal)
		{
			await this.addGoal()
		}
		else
		{
			await this.saveCycle()
		}
	}

	async addGoal()
	{
		try{
			const data = {
					id       : this.goal!.id,
					asin     : this.kidgoal.asin,
					classGoal: (this.kidId == 0),
					classId  : this.goal!.classId,
					details  : this.goal!.details,
					end      : this.kidgoal.end,
					kidGoalId: this.kidgoal.id,
					kidId    : this.kidId,
					name     : this.goal!.name,
					prize    : this.kidgoal.prize,
					prize_url : this.kidgoal.prize_url,
					shape    : this.kidgoal.shape,
					start    : this.kidgoal.start,
					total    : this.goal!.total,
				}

			let response = await axios.post( '/api/goal', data, { withCredentials: true }	);
			let goalId = response.data.o.goal.id

			if(router.currentRoute.name == 'kids')
				router.push({name: 'kids', params:{clsId:this.goal!.classId!.toString(), kidId:this.goal!.kidId.toString(), goalId: goalId.toString()}},() => {})
			// else if(router.currentRoute.name == 'goals')
			// 	router.push({name: 'goals', params:{clsId:this.goal!.classId!.toString(), goalId: goalId.toString()}},() => {})

			Evt.ClassGoals_Refresh.Emit()
			//Evt.GoalView_Refresh.Emit()	not needed since GoalView.onGoalChange should take care of this
			Evt.ClassGoalView_Refresh.Emit()
			Evt.BarGoalsRefresh.Emit()

			this.close()
		}
		catch(ex)
		{
			this.problem = ex.response.data.message
		}
	}

	async saveCycle()
	{
		let message = 'Successfully added cycle'
		if(this.mode == DlgCycleMode.EditCycle)
			message = 'Successfully updated cycle'

		try {
			this.kidgoal.goalId = this.goal!.id
			this.kidgoal.clsId  = this.classId
			//this.kidgoal.usrId  = this.goal?.classGoal ? null : this.goal!.kidId	// Dont send usrId will create KidGoals for all users if classGoal
			this.kidgoal.usrId  = this.kidId && !this.goal?.classGoal ? this.kidId : null
			this.kidgoal.prize = this.amazonData?.title

			const response = await axios.post(`/api/cycle/${this.orgStart}`, this.kidgoal, {withCredentials: true})

			if(!response.data.success)
			{
				this.problem = response.data.message
			}
			else
			{
				this.close()
				this.kidgoal.id = response.data.o.id

				Evt.ClassGoals_Refresh.Emit()
				Evt.CycleSaved.Emit(this.kidgoal, this.mode)

				this.$buefy.notification.open({
					message,
					position: 'is-top',
					type: BuefyType.success,
				})
			}
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	async refreshImage()
	{
		const amazonUrl = this.kidgoal.prize_url
		if(!amazonUrl)
			return

		let match = this.rgxASIN.exec(amazonUrl)
		if(match && match.length >= 2)
		{
			this.kidgoal.asin = match[1]
			const url = '/api/get-amazon-image-url/' + this.kidgoal.asin
			try {
				const response = await axios.get(url, {withCredentials: true});
				this.amazonData = response.data
				this.$storage.set(this.kidgoal.asin, this.amazonData.image)
			} 
			catch (ex) { this.$handleError(ex, 'Error') }
		}
	}

	async deleteCycle()
	{
		Evt.Show_DlgConfirm.Emit('cycle', 'Delete')
	}

	async confirmDelete(): Promise<void>
	{
		try 
		{
			const response = await axios.delete('/api/cycle/' + this.kidgoal.id, {withCredentials: true})
			if(!response.data.success)
			{
				this.problem = response.data.message
				return
			}
			else
			{
				this.$buefy.notification.open({
					message: `Cycle successfully deleted`,
					position: 'is-top',
					type: BuefyType.success,
				})

				Evt.GoalView_Refresh.Emit()
				router.push({name: 'kids', params:{clsId:this.goal!.classId!.toString(), kidId:this.goal!.kidId.toString(), goalId: this.goal!.id.toString()}},() => {})
				
				this.close()
			}
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}
}
