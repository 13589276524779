


















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { BuefyType } from '@/lib/BuefyType'
import { RedemptionInfo } from '../shared/RedemptionInfo.dto'

@Component({
	components: {},
	mixins: [],
})
export default class DlgReferrals extends Dialog
{
	redemptionInfo = {} as RedemptionInfo

	get refCode()
	{
		return Store.LoggedInUser!.referralCode
	}

	get refUrl()
	{
		return `${window.location.protocol}//${window.location.host}?r=${this.refCode}`
	}
	
	mounted()
	{
		Evt.Show_DlgReferrals.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.Show_DlgReferrals.Off(this.Display)
	}

	Display()
	{
		this.visible = true
		this.refresh()
	}

	async refresh()
	{
		try {
			const response = await axios.get('/api/referrals', {withCredentials: true})
			this.redemptionInfo = response.data
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	close()
	{
		this.visible = false
	}

	async submit()
	{
		this.close()
	}

	copyUrl(): void
	{
  		const copyFrom:HTMLElement|null = document.querySelector('.refurl')
		const refUrl = copyFrom!.innerText
  		navigator.clipboard.writeText(copyFrom!.innerText).then(() => 
		{
			this.$buefy.notification.open({
				message: `Successfully copied URL to clipboard`,
				position: 'is-top',
				type: BuefyType.success,
			})
			console.log('copied', refUrl)
		})
	}

}
