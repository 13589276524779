
































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate';
import { extend } from 'vee-validate';
import { alpha_dash, alpha_num, confirmed, email, integer, length, max, max_value, min_value, required, excluded } from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import Multiselect from 'vue-multiselect'
import { DateTime } from "luxon"
import Evt from '@/lib/Evt'

Vue.component('multiselect', Multiselect)

localize({
en: {
	messages: {
		required: 'this field is required',
		min: 'Min {length} characters',
		max: (_, { length }) => `Max length ${length} characters`,
		min_value: (_, { min }) => `Min value is ${min}`,
		max_value: (_, { max }) => `Max value is ${max}`,
		alpha_dash: 'Only alphabets, numbers, dash and underscore allowed',
		alpha_num: 'Only alphabets and numbers allowed',
		excluded: 'Invalid selection',
	}
}
});
// No message specified.
extend('alpha_dash', alpha_dash)
extend('alpha_num', alpha_num)
extend('email', email)
extend('integer', {...integer, message: "Must be whole number"})
extend('length', length)
extend('max', max)
extend('max_value', max_value)
extend('min_value', min_value)
extend("confirmed", {
	...confirmed,
	message: "Passwords don't match"
})
// Override the default message.
extend('required', {
	...required,
	message: 'This field is required'
})
extend('excluded', excluded)

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// sets vee-validate mode globally
// vee-validate mode based on "eager" and scrolls to the first invalid field
setInteractionMode('custom', context => 
{
	if (context.errors.length) {

		// scrolls to first invalid field
		document.querySelector('.is-danger')?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})

		return {
			on: ['input', 'change']
		}
	}

	return {
		on: ['change', 'blur']
	}
});



@Component({
	//components: {},
	components: {  },
	props: {},
})
export default class MainApp extends Vue 
{
	deployed:string|null = ""
	
	key:string|null = ""

	

	mounted()
	{
		let date = this.$refs.date as HTMLElement
		this.deployed = date.textContent
		this.key = this.deployed
		//console.log("app mounted deployTime: ", this.key)

		Evt.App_Refresh.On(this.reload)
	}

	reload(key: string)
	{
		this.key = key
		//console.log("app deployTime:", key)
		

		//this.$forceUpdate() doesn't fetch the new app
		if(key)	// don't reload if there's no key
			location.reload()
		//console.log("app UPDATED deployTime:", key)
	}

}


