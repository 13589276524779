

































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Evt from '@/lib/Evt'
import NavBar from '@/components/NavBar.vue'
import DlgGoal from '@/components/DlgGoal.vue'
import DlgStep from '@/components/DlgStep.vue'
import BarKidGoals from '@/components/BarKidGoals.vue'
import GoalView from '@/components/GoalView.vue'
import { Store } from '@/store'

@Component({
	components: {NavBar, BarKidGoals, GoalView, DlgGoal, DlgStep},
	mixins: [],
})
export default class Kid extends Vue
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidGoalId!: number	//cycle

	Evt = Evt	// so navbar can accesss it
	
	get kidId()
	{
		return Store.LoggedInUser!.id
	}

	mounted()
	{
		if(!Store.LoggedInUser)
			Store.Logout(true)
	}
}
