



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Shape } from '@/lib/Shape'

@Component({
	components: {},
	props: {},
	mixins: []
})
export default class GoalStars extends Vue 
{
	@Prop({ type: Number, required: false, default: 0 }) readonly achieved!: number;
	@Prop({ type: Number, required: false, default: 0 }) readonly total!: number;
	@Prop({ type: Number, required: false, default: 0 }) readonly shape!: number;
	@Prop({ type: Function, required: false           }) readonly AddRemove!: (...args: any[]) => void;
	
	shapes = Shape

	get size() //computed
	{	
		const sz = 4 - Math.log10(this.total)
		return sz + "rem"

	}

}
