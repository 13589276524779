




























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'

@Component({
	components: {},
	mixins: [],
})
export default class DlgCancelSubscription extends Dialog
{
	protected reason = null
	protected feedback = null

	mounted()
	{
		Evt.Show_DlgCancelSubscription.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.Show_DlgCancelSubscription.Off(this.Display)
	}


	public Display()
	{
		this.visible = true
	}

	protected close()
	{
		this.visible = false
	}

	protected async submit()
	{
		try {
			const url = "/signup/cancel-subscription"
			const response = await axios.post(url,
				{
					reason: this.reason,
					feedback: this.feedback,
				},
				{withCredentials: true})
			
			Store.Logout(false)
			
			this.$buefy.notification.open({
				message: `Your subscription was successfullly cancelled`,
				position: 'is-top',
				type: 'is-success',
				duration: 12*3600*1000
			})
		} 
		catch (ex) { this.$handleError(ex, 'Error') }

		this.close()
	}

}
