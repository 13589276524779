var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"dialog-background"},[_c('div',{staticClass:"dialog DlgLogin"},[_c('div',{staticClass:"header"},[_c('Logo'),_c('span',{staticClass:"title"},[_vm._v("Login")])],1),_c('div',{staticClass:"dialog-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Email/Username","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{ref:"email",attrs:{"name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"left"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v("Login")]),_c('b-button',{attrs:{"type":"is-light"},on:{"click":_vm.close}},[_vm._v(" Cancel")])],1),_c('div',{staticClass:"right"},[(_vm.signupVisible)?_c('a',{attrs:{"href":"/select-plan"}},[_vm._v("Sign up")]):_vm._e()])]),_c('div',{staticClass:"footer2"},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.forgotPassword}},[_vm._v("Forgot password")])])])]}}],null,false,702359205)})],1)]),(_vm.problem)?_c('div',{staticClass:"problem"},[_vm._v(_vm._s(_vm.problem))]):_vm._e(),_vm._m(0)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updates"},[_c('h2'),_c('ul',[_c('li')])])}]

export { render, staticRenderFns }