

















































































































































































































































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import axios from 'axios'
import GoalStars from '@/components/GoalStars.vue'
import Empty from '@/components/Empty.vue'
import Evt, { DlgCycleMode } from '@/lib/Evt'
import { StepDTO } from '../shared/step.dto';
import DateUtils from '@/lib/DateUtils'
import Avatar from '@/components/Avatar.vue'
import { Store } from '@/store'
import { Role } from '../shared/Permissions'
import Confetti from '@/components/Confetti.vue'
import { Shape } from '@/lib/Shape'
import GoalGraph from '@/components/GoalGraph.vue'

import Calendar from 'v-calendar/lib/components/calendar.umd'
import { VCalendarAttribute } from '@/lib/VCalendarAttribute'

import Velocity from 'velocity-animate'

import router from '@/router'
import { Goal, Kidgoal } from '../shared/Goal.dto'
import CycleSelect from '@/components/CycleSelect'


@Component({
	components: { GoalStars, Avatar, Empty, 
		Confetti,
		Calendar,
		GoalGraph,
		CycleSelect,
	},
	mixins: [],
})
export default class GoalView extends Vue 
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidId!: number	
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidGoalId!: number	//cycle

	@Ref() readonly goalstars!: GoalStars

	DateUtils = DateUtils  // to make usable in view

	shapes = Shape
	blankGoal = new Goal()
	
	goal = this.blankGoal
	kidGoal:Kidgoal|undefined = undefined
	image = ""
	start = ""
	end = ""
	steps:StepDTO[] = []
	graphData:number[] = []

	confettiConfig = {
		angle: '45',
		spread: '70',
		startVelocity: '100',
		elementCount: '90',
		dragFriction: '0.13',
		duration: 4000,
		stagger: '2',
		width: '12px',
		height: '12px',
		colors: ['#FF4767', '#1AA8B5']
	}

	month = new Date().getMonth()
	year = new Date().getFullYear()

	masks = {
		weekdays: 'WWW',
	  }

	slide_down(el:HTMLElement, done:boolean) {
    	Velocity(el, 'slideDown', {
        duration: 'fast',
        complete: done
    	})
	}

	slide_up(el:HTMLElement, done:boolean) {
		Velocity(el, 'slideUp', {
			duration: 'fast',
			complete: done
		})
	}
	
	cyclesOpen = false

	toggleCycles(): void
	{
		this.cyclesOpen = !this.cyclesOpen
	}

	selectCycle(start: string, refresh = true): void
	{
		const selectedCycle = this.goal.kidgoals.find( i => i.start == start)
		if(selectedCycle)
		{
			router.push( { 
				name: this.$route.name!,
				params: {
					clsId:     this.clsId.toString(), 
					kidId:     this.kidId.toString(), 
					goalId:    this.goalId.toString(),
					kidGoalId: selectedCycle.id.toString(),
				}},() => {})
		
			if(refresh)
				this.Refresh()
		}
		else
		{
			console.error('Cannot select cycle: ', start)
		}
	}
	
	addCycle(): void
	{
		let kidgoal = new Kidgoal()
		kidgoal.usrId = this.kidId
		kidgoal.clsId = this.clsId
		kidgoal.goalId = this.goalId
		Evt.Show_DlgCycle.Emit( DlgCycleMode.AddCycle, kidgoal, this.goal)
	}

	editCycle(): void
	{
		Evt.Show_DlgCycle.Emit( DlgCycleMode.EditCycle, this.kidGoal!, this.goal)
	}	

	cycleSaved(cycle: Kidgoal, mode: DlgCycleMode): void
	{
		if(mode != DlgCycleMode.EditCycle)
		{
			this.goal.kidgoals.push(cycle)
			this.goal.kidgoals.sort( Kidgoal.Sort )
			router.push({name: this.$route.name!,
						 params:{clsId:this.goal!.classId!.toString(), 
						 kidId:this.goal!.kidId.toString(), 
						 goalId: cycle.goalId.toString(), 
						 kidGoalId: cycle.id.toString() }},() => {})
		}

		this.kidGoal = cycle
		this.Refresh()
	}

	size(quantity:number): string //computed
	{	
		const max = this.$isMobileView() ? 1.5 : 2
		let sz = max - Math.log10(quantity)
		if(sz <= 0)
			sz = 0.1
		return sz + "rem"
	}

	attributes:VCalendarAttribute[] = []	// for the calendar
	
	numStars(quantity: number):string
	{
		let word = 'star'
		if(quantity > 1)
			word = 'stars'
		return quantity + ' ' + word
	}

	get isKidView(): boolean
	{
		return ( Store.LoggedInUser!.role == Role.kid)
	}

	async mounted()
	{
		Evt.GoalView_Refresh.On( this.Refresh )
		Evt.CycleSaved.On(this.cycleSaved)
		this.Refresh()
	}

	beforeDestroy()
	{
		Evt.GoalView_Refresh.Off( this.Refresh )
		Evt.CycleSaved.Off(this.cycleSaved)
	}

	@Watch('clsId')
	@Watch('goalId')
	@Watch('kidId')
	onGoalChange(n: string, o: string) 
	{
		//console.log(`GoalView: clsId:${this.clsId} kidId:${this.kidId} goalId:${this.goalId}` )
		this.Refresh()
	}


	get canAddStar()
	{
		return Store.LoggedInUser!.permissions.steps
	}


	get canEdit()
	{
		return Store.LoggedInUser!.permissions.goals
	}


	async Refresh()
	{
		if(!this.kidId || !this.goalId)
		{	
			this.goal = this.blankGoal
			this.start = ""
			this.end = ""
			return 
		}

		// get goal info
		try {
			const url = `/api/goal/${this.kidId}/${this.goalId}`
			const response = await axios.get(url, {withCredentials: true})
			if(response.data && response.data.length)
			{	
				this.goal = response.data[0]
				this.goal.classId = this.clsId
				this.goal.kidId = this.kidId
				
				if(this.kidGoalId)
					this.kidGoal = this.goal.kidgoals.find(x => x.id == this.kidGoalId)
				else
				{
					this.kidGoal = this.goal.kidgoals[ 0 ]
					this.selectCycle(this.kidGoal.start.toString(), false)
				}
			}
			else
			{
				this.goal = this.blankGoal
				console.log("GoalView.Refresh(): received no data")
			}
		} 
		catch (ex) { this.$handleError(ex, "Couldn't load goal") }

		// get steps
		try {
			const url = `/api/steps/${this.kidGoal?.id}`	//TODO: Get the right cycle
			const response = await axios.get(url, {withCredentials: true});
			this.steps = response.data
			this.graphData = this.calculateGraphData(this.steps)

			this.attributes = []
			let prevAttr:VCalendarAttribute|null = null

			this.steps.forEach( (step, index) => 
			{	
				let date = new Date(step.moment || step.created_at) 	// moment may be null for old values
				if(prevAttr && DateUtils.SameDay(prevAttr.dates, date))
				{
					prevAttr.customData.quantity += step.quantity
					prevAttr.customData.title += "\n" + step.note
					prevAttr.key += ',' + step.quantity
				}
				else
				{
					let attr:VCalendarAttribute = {
						key: index.toString(),
						dates: new Date(date),
						customData: {
							title: step.note,
							quantity: step.quantity > 0 ? step.quantity : 0,
						},
					}
					this.attributes.push(attr)
					prevAttr = attr
				}
			})
		} 
		catch (ex) 
		{
			this.$handleError(ex, "Couldn't load stars")
		}

		// get image

		if(this.goal && this.kidGoal?.asin)
		{
			 // @ts-ignore
			this.image = await this.$storage.remember(this.kidGoal.asin!, async () => {
				try {
					const url = Store.BaseURL + '/api/get-amazon-image-url/' + this.kidGoal?.asin
					const response = await axios.get(url, {withCredentials: true});
					return response.data.image
				} 
				catch (ex) { this.$handleError(ex, 'Error') }
			})
		}
	}


	editGoal()
	{
		Evt.EditGoal.Emit(this.clsId, this.kidId, this.goalId, this.kidGoal?.id)
	}

	calculateGraphData(steps:StepDTO[])
	{
		let graphData:number[] = []
		if(steps.length == 0)
			return graphData

		let prevDate = new Date( steps[0].created_at )
		let qty = steps[0].quantity

		for(let i=1; i<steps.length; i++)
		{
			const step = steps[i]
			const date:Date = new Date( step.moment || step.created_at )
			
			if( !DateUtils.SameDay( prevDate, date ) )
			{
				const daysBetween = DateUtils.DaysBetween(date, prevDate)
				if(daysBetween >= 1)
				{ 
					let zeroDays = new Array( daysBetween - 1 ).fill(qty)
					graphData = graphData.concat(zeroDays)

					graphData.push(qty)	// push the old value
				}
			}

			qty += step.quantity
			prevDate = date
		}
		graphData.push(qty)
		return graphData
	}

	addStar()
	{
		if(this.kidGoal)
			Evt.ShowDlgStep.Emit( this.kidGoal.id, this.kidGoal?.achieved, this.goal.total )
	}
}
