





































import { Vue, Component, Prop } from 'vue-property-decorator'
import Evt from '@/lib/Evt'
import { Store } from '@/store'

@Component({
	components: {},
	mixins: [],
})
export default class GoalGraph extends Vue
{
	@Prop({ type: Number, required: true  }) readonly achieved!: number;
	@Prop({ type: Number, required: true  }) readonly total!: number;	
	@Prop({ type: Boolean, default: false }) readonly showNumbers!: boolean;	
}
