











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
	components: {
	}
})

export default class Problem extends Vue
{
	@Prop({ required: true }) readonly problem!: string[] | string

	mounted()
	{
	}

}

