



















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { Store } from '@/store'
import { Role } from '../shared/Permissions'
import { BuefyType } from '@/lib/BuefyType'
import Logo from '@/components/Logo.vue'

@Component({
	components: {
		Logo
	}
})

export default class DlgLogin extends Dialog 
{
	protected email = ""
	protected password = ""
	protected confirm = ""
	protected problem = ""

	get signupVisible()
	{
		return window.location.hostname.includes('kidrewards.org') || window.location.hostname.includes('localhost')
	}

	public Show(show = true)
	{
		this.visible = show
				
		if(this.visible){
			Vue.nextTick(() => {
				const nextField = this.$refs.email as HTMLElement
				nextField.focus();		
			})
		}
	}

	async forgotPassword()
	{
		if(!this.email)
		{
			this.problem = "Please enter username or email"
			return
		}

		this.problem = ""
		try {
			const url = "/user/forgot-password"
			const response = await axios.post(url,
				{
					emailOrUsername: this.email
				},
				{withCredentials: true})

			if(response.data.success)
			{
				this.$buefy.notification.open({
						message: `An email has been sent with instructions on how to reset your password`,
						position: 'is-top',
						type: BuefyType.success,
					})
			}
			else{
				this.$buefy.notification.open({
						message: response.data.message,
						position: 'is-top',
						type: BuefyType.danger
					})
			}
		} 
		catch (ex) { this.$handleError(ex, 'Error') }
	}

	protected close()
	{
		this.Show(false)
		this.problem = ""
		this.$emit('closed')
	}

	protected async login()
	{
		const url = Store.BaseURL + '/user/login'
		try{
			const response = await axios.post(url, {
					username: this.email.trim(),	//todo: work with email or username
					password: this.password,
					screen: this.$screen,
				},
				{withCredentials : true}
			);

			const user = response.data.o
			Store.SetUser(user)
			Store.SetApiDeployTime(response.data.deployTime)
			console.log("deployTime from Login:", response.data.deployTime)
			
			this.$buefy.toast.open({
					message: 'Logged in successfully!',
					type: 'is-success',
				});

			this.password = ""
			this.confirm = ""

			Store.SetStripePubKey( response.data.extra )

			if(user.role == Role.kid)
				this.$router.push({name: 'kid'})
			else if(user.role == Role.parentClass)
				this.$router.push({name: 'parent'})
			else
				this.$router.push({name: 'kids'})

			this.close()
		}
		catch(ex)
		{ 
			if(ex.response.status == 401)
			{
				// this.$buefy.notification.open({
				// 	message: `Invalid username or password`,
				// 	position: 'is-top',
				// 	type: 'is-danger',
				// })
				
				this.problem = ex.response?.data?.message
				return
			}

			this.$handleError(ex, "Login failed") 
		}
	}

}

