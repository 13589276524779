








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { BuefyType } from '@/lib/BuefyType'

@Component({
	components: {},
	mixins: [],
})
export default class DlgInviteLink extends Dialog
{
	inviteLink = ''

	mounted()
	{
		Evt.Show_DigInviteLink.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.Show_DigInviteLink.Off(this.Display)
	}


	Display(key: string)
	{
		this.visible = true
		this.inviteLink = `${window.location.protocol}//${window.location.host}/sign-up/20/${key}`
	}

	close()
	{
		this.visible = false
	}

	async submit()
	{
    	let textToCopy = document.querySelector('#invite-link') as HTMLInputElement
		textToCopy.select()
		document.execCommand("copy");

		this.$buefy.notification.open({
			message: `Successfully copied invite link`,
			position: 'is-top',
			type: BuefyType.success,
		})
	}
}
