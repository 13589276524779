var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Signup form-page"},[_c('div',{staticClass:"form-header"},[_c('Logo')],1),_c('div',{staticClass:"form-main"},[_c('h1',[_vm._v("Sign up: "+_vm._s(_vm.plan)+" plan")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signup)}}},[_c('div',{staticClass:"form-body"},[_c('validation-provider',{attrs:{"rules":"required|max:16|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"First name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"firstname"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|max:16|alpha_dash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Last name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"lastname"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email|max:254","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Email","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|max:16|alpha_dash","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Username","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"Confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Confirm password","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"password","name":"confirm"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})],1)]}}],null,true)}),(_vm.role == _vm.Role.admin)?_c('div',{staticClass:"row span-rows-2"},[_c('validation-provider',{attrs:{"rules":{ required: _vm.role == _vm.Role.admin },"name":"School"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"School","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"school"},model:{value:(_vm.school),callback:function ($$v) {_vm.school=$$v},expression:"school"}})],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"row span-rows-2"},[_c('validation-provider',{attrs:{"rules":"max:8|alpha_num","name":"RefCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Referral Code (optional)","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"refCode"},model:{value:(_vm.refCode),callback:function ($$v) {_vm.refCode=$$v},expression:"refCode"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"row tos span-rows-2"},[_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-checkbox',{attrs:{"name":"tos"},model:{value:(_vm.tosAccepted),callback:function ($$v) {_vm.tosAccepted=$$v},expression:"tosAccepted"}}),_c('span',[_vm._v("By signing up, you agree to KidRewards’s "),_c('a',{attrs:{"href":_vm.urlTOS,"target":"_blank"}},[_vm._v("Terms of Service ")]),_vm._v("and "),_c('a',{attrs:{"href":_vm.urlPrivacy,"target":"_blank"}},[_vm._v("Privacy Policy. ")]),_vm._v("Since KidRewards is based in the US, you also agree that your information could be transferred there. ")]),_c('p',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"ftr"},[_c('div',{staticClass:"left"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v("Sign up")]),_c('b-button',{attrs:{"type":"is-light"},on:{"click":function($event){return _vm.router.go(-1)}}},[_vm._v(" Cancel")])],1),_c('div',{staticClass:"right"},[_c('a',{attrs:{"href":"/?action=login"}},[_vm._v("Login instead")])])]),_c('Problem',{attrs:{"problem":_vm.problem}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }