










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Api from '@/mixins/ApiCalls'
import DlgClass from '@/components/DlgClass.vue'
import SubBarClassGoals from '@/components/SubBarClassGoals.vue'
import Evt from '@/lib/Evt'
import router from '@/router';
//import {mapState} from 'vuex'
import {Tab} from '@/lib/Tab'
import { Store } from '@/store'
import Empty from '@/components/Empty.vue'
import {Class} from '../shared/KidGoals.dto'


@Component({
	components: { DlgClass, SubBarClassGoals, Empty },
	mixins: [Api,],
	//computed: mapState([ 'BaseURL' ])
})
export default class BarKidGoals extends Vue 
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number	//only needed to pass it down 
	
	data:Class[] = []
	visible = true
	left = '0'
	
	get kidFirstName(): string|undefined
	{
		return Store.LoggedInUser!.firstname
	}

	get kidLastName(): string|undefined
	{
		return Store.LoggedInUser!.lastname
	}	

	get title()
	{
		if(this.data.length == 1)
			return "Goals"
		else
			return "Classes & Goals"
	}

	get kidId()
	{
		return Store.LoggedInUser!.id
	}

	async mounted()
	{
		Evt.BarKidGoals_Refresh.On(this.refresh)
		Evt.Show_BarKidGoals.On(this.show)

		await this.refresh()
		
		if(this.data.length > 0)
		{
			this.selectClass(this.data[0].id)	//expand first class
		}
	
		if(this.clsId && this.goalId)
			this.selectGoal(this.clsId, this.goalId)
	}

	beforeDestroy()
	{
		Evt.BarKidGoals_Refresh.Off(this.refresh)
		Evt.Show_BarKidGoals.On(this.show)
	}

	show(visible: boolean)
	{
		this.visible = visible

		if(visible)
		{
			this.left = '0'
		}
		else
		{
			this.left = '-220px'	//TODO use variables
		}
	}
	protected selectClass(classId:number)
	{
		router.push({name: 'kid', params:{clsId:classId.toString()}},() => {})
	}

	protected selectGoal(clsId: number, goalId: number)
	{
		const cls = this.data.find(i => i.id == clsId)
		if(!cls)
		{
			console.log("class not found", clsId)
			return
		}

		if( this.$isMobileView() )
		{
			Evt.Show_BarKidGoals.Emit(false)
		}

		const kid = cls.kidgoals!.find(i => i.goal!.id == goalId)
		router.push({name: 'kid', params:{clsId:this.clsId.toString(), kidId:this.kidId.toString(), goalId:goalId.toString()}},() => {})
	}

	protected async refresh()
	{
		try {
			const response = await axios.get('/api/kid-goals', {withCredentials: true});
			this.data = response.data
			this.selectGoal(this.clsId, this.goalId)
		} 
		catch (ex)
		{
			this.$handleError(ex, "Couldn't load classes")
		}
	}

	protected addGoal()
	{
		Evt.AddGoal.Emit(this.clsId, Store.LoggedInUser!.id)
	}


}
