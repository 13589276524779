





































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DateUtils from '@/lib/DateUtils'
import { Goal, Kidgoal } from '../shared/Goal.dto'
import Velocity from 'velocity-animate'
import Evt, { DlgCycleMode } from '@/lib/Evt'
import GoalGraph from '@/components/GoalGraph'

@Component({
	components: { GoalGraph },
	props: {},
	mixins: []
})
export default class CycleSelect extends Vue 
{
	@Prop({ type: Object, required: true}) readonly  goal!:       Goal	//selected kidgoal
	@Prop({ type: Object, required: true}) readonly  kidGoal!:    Kidgoal	//selected kidgoal
	@Prop({ type: Array,  required: true}) readonly  kidGoals!:   Kidgoal[]
	@Prop({ type: Number, required: true }) readonly total!:      number
	@Prop({ type: Boolean, required: true }) readonly graphVisible!: boolean
	@Prop({ type: Boolean, required: true }) readonly allowAdd!:  boolean
	
	DateUtils = DateUtils  // to make usable in view
	cyclesOpen = false

	toggleCycles(): void
	{
		this.cyclesOpen = !this.cyclesOpen
	}

	slide_down(el:HTMLElement, done:boolean) {
    	Velocity(el, 'slideDown', {
        duration: 'fast',
        complete: done
    	})
	}

	slide_up(el:HTMLElement, done:boolean) {
		Velocity(el, 'slideUp', {
			duration: 'fast',
			complete: done
		})
	}

	selectCycle(index: number)
	{
		this.$emit('selectCycle', index)
	}

	addCycle(): void
	{
		let kidgoal = new Kidgoal()
		kidgoal.usrId = this.kidGoal.usrId
		kidgoal.clsId = this.kidGoal.clsId
		kidgoal.goalId = this.goal.id
		Evt.Show_DlgCycle.Emit(DlgCycleMode.AddCycle, kidgoal, this.goal)
	}

	editCycle(): void
	{
		Evt.Show_DlgCycle.Emit(DlgCycleMode.EditCycle, this.kidGoal!, this.goal)
	}
}
