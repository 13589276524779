import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Start from '@/views/Start.vue'
import { component } from 'vue/types/umd';

import Kids from '@/views/Kids.vue'
import Kid from '@/views/Kid.vue'
import Signup from '@/views/Signup.vue'
import SelectPlan from '@/views/SelectPlan.vue'
import SignupSuccess from '@/views/SignupSuccess.vue'
import TrialExpired from '@/views/TrialExpired.vue'
import Users from '@/views/Users.vue'
import { Store } from '@/store'
import { Tab }  from "@/lib/Tab"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = 
[
	{ path: '/', name: 'Start', component: Start, },
	{ path: '/select-plan', name: 'SelectPlan', component: SelectPlan },
	{ path: '/trial-expired', name: 'TrialExpired', component: TrialExpired, meta: { requiresAuth: true }, },
	{ path: '/sign-up/:role/:targetClass?', name: 'Signup', 
		component: Signup, meta: { requiresAuth: false },
		props:(route) => ({
			role: parseInt(route.params.role),
			targetClass: route.params.targetClass,
		})},
	{ name: 'success', path: '/success/:sessionId',
		component: SignupSuccess, meta: { requiresAuth: false },
		props:(route) => ({
			sessionId: route.params.sessionId
			})},
	{ name: 'users', path: '/Users', 
		component: Users, meta: { requiresAuth: true },
		
	},
	{ name: 'kids', path: '/kids/:clsId?/:kidId?/:goalId?/:kidGoalId?',
		component: Kids, meta: { requiresAuth: true },
		props:(route) => ({
			tab: Tab.kids,
			clsId:     parseInt(route.params.clsId)     || 0,
			kidId:     parseInt(route.params.kidId)     || 0,
			goalId:    parseInt(route.params.goalId)    || 0,
			kidGoalId: parseInt(route.params.kidGoalId) || 0,
			})},
	{ name: 'kid', path: '/kid/:clsId?/:goalId?/:kidGoalId?', 
		component: Kid, meta: { requiresAuth: true },
		props:(route) => ({
			clsId:  parseInt(route.params.clsId)  || 0, 
			goalId: parseInt(route.params.goalId) || 0, 
			kidGoalId: parseInt(route.params.kidGoalId) || 0,
			})},			
	{ name: 'goals', path: '/goals/:clsId?/:goalId?/:start?',
		component: Kids, meta: { requiresAuth: true },
		props:(route) => ({
			tab: Tab.goals,
			clsId:  parseInt(route.params.clsId)  || 0, 
			goalId: parseInt(route.params.goalId) || 0,
			start: route.params.start || null,
			})},
	{ name: 'parent', path: '/parent/:kidId?/:clsId?/:goalId?/:kidGoalId?', 
		component: Kids, meta: { requiresAuth: true },
		props:(route) => ({
			tab: Tab.parent,
			kidId:  parseInt(route.params.kidId)  || 0,
			clsId:  parseInt(route.params.clsId)  || 0, 
			goalId: parseInt(route.params.goalId) || 0,
			kidGoalId: parseInt(route.params.kidGoalId) || 0,
			})},			
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;

router.beforeEach((to, from, next) => 
{
	let currentUser = Store.LoggedInUser
	let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	
	if (requiresAuth && !currentUser) {
		next('/')
	} 
	else {
		next()
	}

})