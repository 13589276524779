




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import {StripeCheckout} from '@/lib/StripeCheckout'
import Logo from '@/components/Logo.vue'

@Component({
	components: { Logo },
	mixins: [],
})
export default class TrialExpired extends Vue
{
	subscribe()
	{
		StripeCheckout.Checkout(Store.LoggedInUser!.school!.id!, Store.LoggedInUser!.school!.plan!, Store.StripePubKey)
	}

	logout()
	{
		Store.Logout(false)
	}
}
