import { loadStripe, Stripe } from '@stripe/stripe-js';
import axios from 'axios'
import { Role } from '../shared/Permissions';


export class StripeCheckout 
{
	public static async Checkout(schoolId: number, role:Role, stripePubKey: string)
	{
		const stripe = await loadStripe(stripePubKey)
		
		if(!stripe)
		{
			console.error("Could not init stripe")
			return
		}
		this.selectPlan(stripe, schoolId, role)
	}

	static async selectPlan(stripe: Stripe, schoolId: number, role: Role)
	{
		const checkoutSessionId = await this.createCheckoutSession(schoolId, role)
		await stripe.redirectToCheckout({ sessionId: checkoutSessionId })
	}

	static async createCheckoutSession(schoolId: number, role: Role)
	{
		const url = "/signup/create-checkout-session"
		try{
			const response = await axios.post( url, { schoolId, role } )
			console.log("checkoutSessionId: ", response.data.checkoutSessionId)
			return response.data.checkoutSessionId
		}
		catch(ex)
		{
			console.error("Error creating check out session")
			console.log(ex)
		}
	}
}