








































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Evt, { DlgCycleMode } from '@/lib/Evt'
import { Store } from '@/store'
import axios from 'axios'
import GoalGraph from '@/components/GoalGraph.vue'
import Empty from "@/components/Empty.vue"
import {BulkStepsDTO} from '../shared/BulkSteps.dto'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { Sound } from '@/lib/Sound'
import { BuefyType } from '@/lib/BuefyType'
import CycleSelect from '@/components/CycleSelect.vue'
import { Goal, Kidgoal } from '../shared/Goal.dto'
import DateUtils from '@/lib/DateUtils'
import router from '@/router'

@Component({
	components: {GoalGraph, Empty, LoadingSpinner, CycleSelect},
})
export default class ClassGoalView extends Vue
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number
	@Prop({ type: String, required: false, default: 0 }) readonly start!: string

	spinnerVisible = false
	sound = new Sound()
	goal = new Goal()
	selectedKidGoals:number[] = []
	quantity = 1
	note = ""
	minAchieved = 0
	maxAchieved = 0
	timepicker = { incrementMinutes: 15 }
	moment = new Date()
	
	cycles:Kidgoal[] = []	// kidgoals with dates and null usrIds to keep track of the different cycles
	selectedCycle?: Kidgoal
	kidgoals:Kidgoal[] = []  // The kidgoals for the selected cycle with the kids

	shouldRefresh = true


	get canAddCycle()
	{
		return Store.LoggedInUser!.permissions.goals
	}

	get className():string
	{
		return Store.SelectedClass ? Store.SelectedClass.name : ""
	}
	
	get action(): string
	{
		return this.quantity >= 0 ? "Add stars" : "Remove stars"
	}

	get message(): string
	{
		return this.quantity >= 0 ? "Added stars successfully" : "Removed stars successfully"
	}

	async mounted()
	{
		Evt.ClassGoalView_Refresh.On(this.refresh)
		Evt.CycleSaved.On(this.cycleSaved)
		this.refresh()
	}

	beforeDestroy()
	{
		Evt.ClassGoalView_Refresh.Off(this.refresh)
		Evt.CycleSaved.Off(this.cycleSaved)
	}

	getKidGoal(id: number)
	{
		return this.kidgoals.find( i => i.id == id )
	}

	@Watch('selectedKidGoals')
	onCheck(checkedList:any, row:any)
	{
		const selectedKG = this.kidgoals.filter(i => this.selectedKidGoals.includes(i.id))	// Get the selected kidGoal objects
		this.minAchieved = selectedKG.reduce( (a, v) => Math.min (a, v.achieved), Infinity )
		this.maxAchieved = selectedKG.reduce( (a, v) => Math.max (a, v.achieved), 0 )

		if(this.quantity < (-this.minAchieved))
			this.quantity = -this.minAchieved

		if(this.quantity > this.goal.total - this.maxAchieved)
			this.quantity = this.goal.total - this.maxAchieved
	}

	async refresh()
	{
		if(!this.clsId || !this.goalId)
		{	
			this.goal = new Goal()
			return 
		}

		this.quantity = 1

		try {
			const url = `/api/kidsByClassGoal/${this.clsId}/${this.goalId}`
			const response = await axios.get(url, {withCredentials: true});
			this.goal = response.data
			this.minAchieved = this.goal.kidgoals.reduce( (a, v) => Math.min (a, v.achieved), Infinity )
			this.maxAchieved = this.goal.kidgoals.reduce( (a, v) => Math.max (a, v.achieved), 0 )

			this.cycles = this.goal.kidgoals.filter(i => !i.usrId)	// Get the kidgoals without users to get the distinct cycles
			this.cycles.sort( Kidgoal.Sort )
			this.selectCycle(this.start)
			this.moment = new Date()
		} 
		catch (ex) 
		{
			this.$handleError(ex, "Couldn't load goal")
		}
	}

	selectCycle(start: string): void
	{
		this.selectedCycle = this.cycles.find( i => i.start == start)
		this.shouldRefresh = false
		router.push({name: 'goals', params:{ clsId: this.clsId!.toString(), 
											 goalId: this.goalId.toString(), 
											 start: start }},() => {})
		this.shouldRefresh = true
		this.kidgoals = this.goal.kidgoals.filter( i => i.usrId && i.start == this.selectedCycle!.start)
	}

	cycleSaved(cycle: Kidgoal, mode: DlgCycleMode): void
	{
		router.push({name: 'goals', params:{clsId: cycle.clsId.toString(), goalId: cycle.goalId.toString(), start: cycle.start.toString() }},() => {})
		Vue.nextTick(() => {
			this.refresh()
		})
	}

	editGoal()
	{
		Evt.EditGoal.Emit(this.clsId, 0, this.goalId)
	}

	async submit()
	{
		const url = Store.BaseURL + '/api/bulk-steps'
		let steps = new BulkStepsDTO()

		steps.goalId     = this.goalId
		steps.kidGoalIds = this.selectedKidGoals
		steps.moment     = this.moment
		steps.note       = this.note
		steps.quantity   = this.quantity
		
		if(steps.kidGoalIds.length == 0)
		{
			this.$buefy.notification.open({
				message: `No kids specified`,
				position: 'is-top',
				type: BuefyType.warning,
			})
			return
		}

		try
		{
			this.spinnerVisible = true
			const response = await axios.post(url, steps, {withCredentials : true});
			
			this.$buefy.toast.open({
					message: this.message,
					type: 'is-success',
				});
			
			this.selectedKidGoals = []
			await this.refresh()
			this.spinnerVisible = false

			let numKidsCompletedGoal = 0

			this.kidgoals.forEach(kg => {
				if(kg.achieved == this.goal.total)
					numKidsCompletedGoal++
			})

			const moreThanHalfKidsCompletedGoal = ( numKidsCompletedGoal >= this.kidgoals.length/2 )

			if( moreThanHalfKidsCompletedGoal )
			{
				this.sound.playFinale()
				// 440 is the width of the two left bars, this prevents the confetti from hiding befind the bars
				Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
				Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
				Evt.PopConfetti.Emit((window.visualViewport.width - 440) * Math.random() + 500, window.visualViewport.height * 0.5 * Math.random() + 100)
			}
			else if(steps.quantity > 0)
			{
				this.sound.playSparkle()	
			}	
		}
		catch(ex)
		{
			this.$buefy.toast.open({
				message: 'Error adding/removing stars',
				type: 'is-danger',
			})
			this.spinnerVisible = false
		}
	}

	get selectAll()
	{
		return this.selectedKidGoals ? this.selectedKidGoals.length == this.kidgoals.length : false
	}

	set selectAll(value)
	{
		let selected: number[] = []

		if (value)
		{
			this.kidgoals.forEach(function (kg)
			{
				selected.push(kg.id)
			})
		}

		this.selectedKidGoals = selected
	}

	get selectAllNoneText()
	{
		if(this.selectAll)
			return "Select none"
		else
			return "Select all"
			
	}
}
