import { DateTime } from "luxon"

export default class DateUtils
{
	public static DaysBetween(a:Date, b:Date):number
	{
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		const diffDays = Math.round(Math.abs((a.getTime() - b.getTime()) / oneDay));
		return diffDays
	}

	public static SameDay(a:Date, b:Date):boolean
	{
		return a.getFullYear() == b.getFullYear() &&
				a.getMonth() == b.getMonth() &&
				a.getDate() == b.getDate()
	}

	// Returns date in local timezone, by default the date will be UTC
	public static GetLocalDateString(dateString:string|null|undefined): string
	{
		if(dateString)
		{
			let dateTime = DateTime.fromISO( dateString )
			return dateTime.toLocal().toLocaleString()
		}
		else
			return ""
	}

	public static GetLocalJSObject(dateString:string|null): Date|null
	{
		if(!dateString)
			return null

		let dateTime = DateTime.fromISO( dateString )
		return dateTime.toLocal().toJSDate()
	}
}