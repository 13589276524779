export class IdleTimer
{
	duration = 60 // seconds
	display = ''
	timer = 0
	start = 0
	counter = 0
	diff = 0
	minutes = ''
	seconds = ''
	events = ['mousemove', 'keypress']
	loop = false
	reminders = [15]
	wait = 0	// before starting
	
	boundResetTimers(){}
	
	idle() 
	{
		
	}

	remind() 
	{
		
	}

	constructor(idle:()=>void, remind:()=>void, duration = 30, reminders = [15], wait = 0)
	{
		this.idle = idle
		this.remind = remind
		
		this.duration = duration
		this.reminders = reminders
		this.wait = wait


	}

	init()
	{
		setTimeout(() =>
		{
			this.start = Date.now()
			this.setDisplay()
			this.setTimer()

			this.boundResetTimers = this.resetTimers.bind(this)
			for (let i = this.events.length - 1; i >= 0; i -= 1)
			{
				window.addEventListener(this.events[i], this.boundResetTimers)
			}

		}, this.wait * 1000)
	}

	setDisplay()
	{
		// seconds since start
		this.diff = this.duration - (((Date.now() - this.start) / 1000) | 0)
		if (this.diff < 0 && !this.loop)
		{
			return
		}
		this.shouldRemind()

		// bitwise OR to handle parseInt
		const minute = (this.diff / 60) | 0
		const second = this.diff % 60 | 0

		this.minutes = `${ minute < 10 ? '0' + minute : minute }`
		this.seconds = `${ second < 10 ? '0' + second : second }`

		this.display = `${ this.minutes }:${ this.seconds }`
	}

	shouldRemind() 
	{
		if (this.reminders.length > 0) 
		{
			if (this.reminders.includes(this.diff)) 
			{
				this.remind()
				this.removeWindowEventListeners()
			}
		}
	}

	countdown() 
	{
		this.setDisplay()

		if (this.diff <= 0 && this.loop)
		{
			// add second to start at the full duration
			// for instance 05:00, not 04:59
			this.start = Date.now() + 1000
		}
	}

	setTimer()
	{
		this.timer = window.setTimeout(this.idle, this.duration * 1000)
		this.counter = window.setInterval(this.countdown.bind(this), 1000)
	}

	resetTimers()
	{
		clearTimeout(this.timer)
		clearInterval(this.counter)
		this.setDisplay()
		this.start = Date.now()
		this.diff = 0
		this.setTimer()
	}

	destroy()
	{
		clearTimeout(this.timer)
		clearInterval(this.counter)
		this.removeWindowEventListeners()
	}

	removeWindowEventListeners()
	{
		for (let i = this.events.length - 1; i >= 0; i -= 1)
		{
			const ret = window.removeEventListener(this.events[i], this.boundResetTimers)
		}
	}
}