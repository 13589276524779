export enum BuefyType
{
	white  ="is-white",
	black  ="is-black",
	light  ="is-light",
	dark   ="is-dark",
	primary="is-primary",
	info   ="is-info",
	success="is-success",
	warning="is-warning",
	danger ="is-danger",
}