




































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Api from '@/mixins/ApiCalls'
import DlgClass from '@/components/DlgClass.vue'
import SubBarClassGoals from '@/components/SubBarClassGoals.vue'
import Evt, { DlgUserMode } from '@/lib/Evt'
import router from '@/router'
//import {mapState} from 'vuex'
import {Tab} from '@/lib/Tab'
import { Store } from '@/store'
import Empty from '@/components/Empty.vue'
import {Class, User} from '@/DTO/ClassKids.dto'
import {Role} from '../shared/Permissions'


@Component({
	components: { DlgClass, SubBarClassGoals, Empty },
	mixins: [Api,],
	//computed: mapState([ 'BaseURL' ])
})
export default class BarClassKid extends Vue 
{
	@Prop({ type: String, required: false, default: Tab.kids }) readonly tab!: string;
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number	//only needed to pass it down to SubBarClassGoals

	Store = Store
	permissions = Store.LoggedInUser!.permissions
	activeTab = 'kids'
	left = '0'
	visible = true
	
	get inviteLinkVisible(): boolean
	{
		return Store.LoggedInUser!.role == Role.teacher
	}

	get classes()
	{
		return Store.Classes
	}
	
	get Tab() 
	{
		return Tab
	}	// make this accessible in the template

	get addVisible()
	{
		return this.permissions.kids || this.permissions.goals || this.permissions.classes
	}

	get canGoal()
	{
		return Store.LoggedInUser!.permissions.goals
	}

	get canClass()
	{
		return this.permissions.classes
	}

	get canKid()
	{
		return this.permissions.kids
	}

	get tabsVisible()
	{
		return (Store.LoggedInUser!.role == Role.teacher ||
				Store.LoggedInUser!.role == Role.admin)
	}

	get AddUserText()
	{
		if(this.permissions.teachers || this.permissions.parents)
			return "Add User"
		else
			return "Add Kid"
	}

	async mounted()
	{
		Evt.BarClassKid_Refresh.On(this.refresh)
		Evt.UnselectClassKid.On(this.unSelect)
		Evt.Show_BarClassKid.On(this.show)

		if( ! Store.LoggedInUser!.school!.active )	// prevent endless redirect loops
			return

		if( Store.LoggedInUser!.role == Role.kid )	// prevent endless redirect loops
			return

		await this.refresh()
		
		if(!Store.LoggedInUser!.permissions!.classes)
		{
			if(!this.clsId)
				this.selectFirstClass()	
		}
	}

	beforeDestroy()
	{
		Evt.BarClassKid_Refresh.Off(this.refresh)
		Evt.UnselectClassKid.Off(this.unSelect)
		Evt.Show_BarClassKid.Off(this.show)
	}

	show(visible: boolean)
	{
		this.visible = visible

		if(visible)
		{
			this.left = '0'
		}
		else
		{
			this.left = '-220px'	//TODO use variables
			Evt.Show_BarGoal.Emit(false)	/// Hide bargoal too
		}
	}

	unSelect():void
	{
		router.push({name: 'kids'}, () => {})
	}

	selectFirstClass()
	{
		if(this.classes.length > 0)
		{
			this.selectClass(this.classes[0].id)
		}
	}

	changeTab(tab: string)
	{
		router.push({name: tab, params:{clsId:this.clsId.toString()}},() => {})
		
		Store.SetSelectedKidName('')
		if(!Store.LoggedInUser!.permissions!.classes && tab == Tab.kids)
		{
			this.selectFirstClass()	
		}
	}

	selectClass(classId:number)
	{
		Store.SetSelectedKidName(``)
		router.push({name: 'kids', params:{clsId:classId.toString()}},() => {})
	}

	selectKid(clsId: number, kidId: number)
	{
		Evt.Show_BarGoal.Emit(true)

		const cls = this.classes.find(i => i.id == clsId)
		if(!cls)
		{
			console.log("class not found", clsId)
			return
		}

		const kid = cls.users.find(i => i.id == kidId)
		if(kid)
		{
			Store.SetSelectedKidName(`${kid!.firstname} ${kid!.lastname}`)
			router.push({name: 'kids', params:{clsId:this.clsId.toString(), kidId:kidId.toString(), goalId:this.goalId.toString()}},() => {})
		}
	}

	async refresh(clsId = 0, kidId = 0)	// pass these values to select the class & kid
	{
		try 
		{
			await Store.GetClasses()
			Store.SetSelectedKidName(``)
			
			if(!clsId)
				clsId = this.clsId

			if(!kidId)
				kidId = this.kidId

			if(clsId && kidId)
				this.selectKid(clsId, kidId)
			else if(clsId)	// clsId present but not kidId
				this.selectClass(clsId)

			if(this.shouldShowAddClassTip())
			{
				Evt.ShowTooltip.Emit('tip-add-class', '.add-item')
			}
			else if(this.shouldShowAddKidTip())
			{
				Evt.ShowTooltip.Emit('tip-add-kid1', '.add-item')
			}				
		} 
		catch (ex)	{ this.$handleError(ex, "Couldn't load classes") }
	}

	async editClass(classId:number)
	{
		Evt.ShowDlgClass.Emit(false, classId)
	}

	addKid()
	{
		Evt.HideTooltip.Emit('tip-click-here')
		Evt.ShowDlgUser.Emit(DlgUserMode.Add, 0, this.clsId)
	}

	addClass()
	{
		Evt.HideTooltip.Emit('tip-click-here')
		Evt.ShowDlgClass.Emit()
	}

	addGoal()
	{
		if(!this.classes || !this.classes.length)
		{
			this.$buefy.notification.open({
				message: `Please add classes and kids first`,
				position: 'is-top',
				type: 'is-danger',
			})
			return
		}
		Evt.AddGoal.Emit(this.clsId, this.kidId)
	}

	onAddDropdownToggle(active: boolean) : void
	{
		if(active)
		{
			if(this.shouldShowAddClassTip())
			{
				Evt.HideTooltip.Emit('tip-add-class')
				Evt.ShowTooltip.Emit('tip-click-here', '.add-class')
			}
			else if(this.shouldShowAddKidTip())
			{
				Evt.HideTooltip.Emit('tip-add-kid1')
				Evt.ShowTooltip.Emit('tip-click-here', '.add-kid')
			}
		}
		else
		{
			Evt.HideTooltip.Emit('')	// hide all
		}
	}

	shouldShowAddKidTip()
	{
		const oneClass = this.classes.length == 1	// don't show if the user has added more than one class
		const noKids   = this.classes[0].users.length == 0 
		return oneClass && noKids
	}

	shouldShowAddClassTip()
	{
		return this.classes.length == 0
	}

	showInviteLink()
	{
		const key = Store.Classes[0].Key
		Evt.Show_DigInviteLink.Emit(key)
	}
}
