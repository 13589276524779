
























































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { Store } from '@/store'
import { BuefyType } from '@/lib/BuefyType'
import Evt from '@/lib/Evt'
import {DlgUserMode}  from '@/lib/Evt'
import { DefaultPermissions, Permission, PlanString, Role, RoleString } from '../shared/Permissions'
import { DateTime } from "luxon"
import { StripeCheckout } from '@/lib/StripeCheckout'
import DlgCancelSubscription from '@/components/DlgCancelSubscription.vue'
import DlgConfirm from '@/components/DlgConfirm.vue'

interface Class
{
	id: number
	name: string
}

export class Parent 
{
    firstname = ""
    lastname = ""
	id = 0

	get FullName(): string
	{
		return this.firstname + " " + this.lastname
	}

	get SearchableName(): string
	{
		return this.firstname + this.lastname
	}
}


@Component({
	components: {
		DlgCancelSubscription, DlgConfirm, 
	}
})
export default class DlgUser extends Dialog 
{
	Role         = Role
	RoleString   = RoleString

	email        = ""
	username 	 = "" 
	firstname    = ""
	lastname     = ""
	password     = ""
	confirmation = ""
	role         = Role.kid
	classes:Class[]   = []

	userPermissions:Permission|undefined = new Permission()

	sendWelcomeEmail = false

	Mode = DlgUserMode	// for the template
	mode 		   = DlgUserMode.Add

	availableClasses:Class[] = []
	filteredClasses:Class[] = []
	isSelectOnly = false
	
	allowNew = false
	openOnFocus = true
	showClasses = true

	id = 0
	Store = Store
	permissions = Store.LoggedInUser!.permissions	// logged in user permissions, not the user being added/created

	selectedParents:Parent[] = []
	removedParents:Parent[] = []
	allParents:Parent[] = []


	get canAddClassParent(): boolean
	{
		return(
			Store.LoggedInUser!.role != Role.parentInd &&	// is NOT independent parent
			Store.LoggedInUser!.permissions.parents			// has permission to add parents
		)
	}

	get canAddIndParent(): boolean
	{
		return(
			Store.LoggedInUser!.role == Role.parentInd &&	// is an independent parent
			Store.LoggedInUser!.permissions.parents			// has permission to add parents
		)
	}

	get archiveVisible(): boolean
	{
		return (
			this.mode == this.Mode.Edit &&
			this.id != Store.LoggedInUser!.id)
	}
	
	@Watch('role')
	onRoleChanged(n: Role, o: Role)
	{
		this.sendWelcomeEmail = (this.role != Role.kid)
		this.userPermissions = DefaultPermissions[ n ] 
	}

	get plan()
	{
		const school = Store.LoggedInUser!.school!
		return PlanString[ school.plan! ]
	}

	get cancelSubscriptionVisible()
	{
		return ( this.permissions.subscribe && this.mode == this.Mode.Profile && this.nextBillDate )
	}
	
	get parentVisible()
	{
		return this.role==Role.kid && this.mode == DlgUserMode.Edit
	}

	get parentsEditable()
	{
		return Store.LoggedInUser!.permissions.parents && this.mode == DlgUserMode.Edit
	}

	get parentIds()
	{
		return this.selectedParents.map(i => i.id)
	}

	get pricing()
	{
		return Store.LoggedInUser!.school!.pricing
	}

	get nextBillDate()
	{
		const dateString = Store.LoggedInUser!.school!.nextBillDate
		if(dateString)
		{
			let dateTime = DateTime.fromISO( dateString )
			return dateTime.toLocal().toLocaleString()
		}
		return null
	}

	get trialEnd()
	{
		const dateString = Store.LoggedInUser?.school?.trialEnd
		if(dateString)
			return DateTime.fromISO( dateString ).toLocal().toLocaleString()	// optional chaining doesn't work for some reason even with TypesScript 3.97
		
		return null
	}

	get classesVisible()
	{
		if(this.mode == this.Mode.Profile)
			return false

		if(this.role == Role.parentClass)
			return false

		return this.permissions.classes || this.availableClasses.length > 1
	}
	
	get title()
	{
		switch(this.mode)
		{
			case DlgUserMode.Add:
				return "Add " + this.userType
			case DlgUserMode.Edit:
				return "Edit " + this.userType
			case DlgUserMode.Profile:
				return "Profile"
			default:
				return ""
		}
	}

	get userType()
	{
		if(this.permissions.kids && !this.permissions.parents && !this.permissions.teachers)
			return "kid"
		else
			return "user"
	}

	get permissionsVisible()
	{
		return (this.mode != this.Mode.Profile)
	}

	get roleSelectorVisible()
	{
		if(this.mode == this.Mode.Profile)
			return false

		const role = Store.LoggedInUser!.role!
		const perm = Store.LoggedInUser!.permissions
		return ( perm.kids && perm.parents || perm.parents && perm.teachers || perm.teachers && perm.kids )
	}

	mounted()
	{
		Evt.ShowDlgUser.On(this.Display)
	}

	beforeDestroy()
	{
		Evt.ShowDlgUser.Off(this.Display)
	}

	getFilteredClasses(text:string) 
	{
		this.filteredClasses = this.availableClasses.filter((option: Class) => {
			return option.name
				.toString()
				.toLowerCase()
				.indexOf(text.toLowerCase()) >= 0
		})
    }
	
	clear()
	{
		this.selectedParents = []
		this.removedParents = []
	}

	async Display(mode: DlgUserMode, userId:number, classId:number)
	{
		this.visible = true
		this.id = userId
		this.mode = mode
		this.problem = ""
		
		//await this.$nextTick()
		//this.focusField(this.$refs.firstname)

		try {
			const url = Store.BaseURL + '/api/classes';
			const response = await axios.get(url, {withCredentials: true});
			this.availableClasses = response.data
			this.getFilteredClasses('')
		} 
		catch (ex) { this.$handleError(ex, "Couldn't load existing classes") }

		if(this.mode != DlgUserMode.Add)
		{
			try {
				const url = Store.BaseURL + '/user/user/' + this.id;
				const response = await axios.get(url, {withCredentials: true});
				let user = response.data
				this.email = user.email
				this.username = user.username
				this.firstname = user.firstname
				this.lastname = user.lastname
				this.role = user.role
				if(user.classes)
					this.classes = this.availableClasses.filter(i => user.classes.find( (j:Class) => j.id == i.id))

				this.userPermissions = user.permissions
			} 
			catch (ex) { this.$handleError(ex, "Couldn't load user") }

			if(this.role == Role.kid)
			{
				this.refreshParents()
				this.getAllParents('')
			}
		}
		else
		{
			this.classes = this.availableClasses.filter(i => i.id == classId)

			this.email         = ""
			this.username 	   = ""
			this.firstname     = ""
			this.lastname      = ""
			this.password      = ""
			this.confirmation  = ""
			
			if(this.permissions.kids)	// select the first default role
				this.role = Role.kid
			else if(this.permissions.parents)
				this.role = Role.parentClass
			else if(this.permissions.teachers)
				this.role = Role.teacher
		}
	}

	removeIdFromList(list: any, id:number): boolean
	{
		const index = list.findIndex( (item:any) => item.id == id)
		if(index < 0)
			return false
		list.splice(index, 1)
		return true
	}

	onParentSelected(value:Parent)
	{
		this.removeIdFromList(this.allParents, value.id)		// remove from the dropdown menu
		this.removeIdFromList(this.removedParents, value.id)	// so this parent doesn't get removed, if it was removed and readded
	}
	
	onParentRemoved(value:Parent)
	{
		if(	! this.allParents.find( (item:Parent) => item.id == value.id) )
			this.allParents.unshift(value)	// add back to dropdown menu, if not already there
		this.removedParents.push( value )	// add to removedParents
		this.removeIdFromList(this.selectedParents, value.id)	// remove from selectedParents
	}

	async getAllParents(search:string)
	{
		try 
		{
			let url = "/user/users"
			url += "?role=" + Role.parentClass

			if(search != "")
				url += "&search=" + search
			
			url += "&sort=lastname"
			
			console.log('DlgUser.getAlParents')
			const response = await axios.get(url, {withCredentials: true});
			this.allParents = response.data[0]
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	async refreshParents()	// get this kid's parents
	{
		try {
			console.log('DlgUser.refreshParents')
			
			const url = "/api/get-parents/" + this.id
			const response = await axios.get(url, {withCredentials: true});
			this.selectedParents = response.data
			//this.getFilteredParents('')
		} 
		catch (ex) { this.$handleError(ex, 'Error') }
	}

	close()
	{
		this.visible = false
		this.clear()
	}

	async submit()
	{
		const url = Store.BaseURL + '/user/save'
		let failureMessage = ''

		let successMsg = "Added successfully"
		if(this.mode != DlgUserMode.Add)
			successMsg = "Updated successfully"

		try{
			const response = await axios.post(url, {
					id       : this.id,
					email    : this.email?.trim(),
					username : this.username.trim(),
					firstname: this.firstname.trim(),
					lastname : this.lastname.trim(),
					password : this.password,
					role     : this.role,
					permissions: this.userPermissions,
					classes: this.classes
				},
				{withCredentials : true}
			)

			
			// --- Remove parents
			for (let parent of this.removedParents)
			{
				try
				{
					const url = `/api/parent/${this.id}/${parent.id}`
					const response = await axios.delete(url, {withCredentials: true})
				} 
				catch (ex) { this.$handleError(ex, 'Error') }
			}

			// --- Add parents
			try {
				const parentIds = this.selectedParents.map( ({id}) => {return {id} })

				const url = '/api/set-parents'
				const response = await axios.post(url,
					{
						childId: this.id,
						parentIds 
					},
					{withCredentials: true});
				
				this.$emit('submit')
			} 
			catch (ex) { this.$handleError(ex, 'Error') }

			
			this.$buefy.toast.open({
					message: successMsg,
					type: 'is-success',
				});


			this.close()
			
			let clsId = 0
			if(this.classes.length)
				clsId = this.classes[0].id

			Evt.BarClassKid_Refresh.Emit(clsId, response.data.o.id)	// select the added/updated kid
			Evt.BarGoalsRefresh.Emit()
			this.$emit('save')
		}
		catch (ex) 
		{ 
			this.problem = ex.response?.data?.message
			this.$handleError(ex, "Error saving user") 
		}
	}

	archive()
	{
		Evt.Show_DlgConfirm.Emit("user")
	}

	confirm(objToArchive: string)
	{
		if(objToArchive == "user")
			this.confirmArchive()
	}

	async confirmArchive()
	{
		try
		{
			const url = '/user/user/' + this.id
			const response = await axios.delete(url, {withCredentials: true})
		} 
		catch (ex) 
		{ 
			this.$handleError(ex, ex.response.data.message) 
			return
		}

		this.close()
		
		Evt.BarClassKid_Refresh.Emit()
	
		
		this.$emit('save')
		if(this.$route.name == "kids")
		{
			this.$emit('archived')
		}	

		this.$buefy.notification.open({
				message: `User successfully archived`,
				position: 'is-top',
				type: 'is-success',
			})		
	}

	async subscribe()
	{
		const school = Store.LoggedInUser!.school!
		StripeCheckout.Checkout( school.id!, school.plan!, Store.StripePubKey)
	}

	cancelSubscription()
	{
		Evt.Show_DlgCancelSubscription.Emit()
	}

	selectParents()
	{
		Evt.Show_DialogParents.Emit()
	}
}

