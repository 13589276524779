

























import { Vue, Component, Prop } from 'vue-property-decorator';

interface RGB {
	r: number;
	g: number;
	b: number;	
	a: number;
}

@Component({
	components: {},
	props: {},
})
export default class Avatar extends Vue {
	@Prop({ type: String, required: true }) readonly firstName!: string;
	@Prop({ type: String, required: true }) readonly lastName!: string;
	@Prop({ type: Number, required: false, default: 32 }) readonly dim!: number;
	


	background = '';
	items: string[] = [];

	// Get a number from 0 to 25 based on a letter
	getCode(c: string) {
		const lc = c.toLowerCase();
		const ascii = lc.charCodeAt(0);
		const code = ascii - 'a'.charCodeAt(0);
		return code;
	}

	// Based on a number from 0-25 returns a color
	// a is the alpha from 0 to 1
	getRGB(n: number, a: number): RGB {
		const mult = 671088; // 2^24 / 26
		const rgb = n * mult;
		const r = (rgb >> 16) & 255;
		const g = (rgb >> 8) & 255;
		const b = rgb & 255;
		return { r, g, b, a };
	}

	// Given two alphabetic characters a and b and an angle in degrees
	// returns a gradient
	getGradient(a: string, b: string, degrees: number): string {
		const ca = this.getCode(a);
		const A = this.getRGB(ca, 0.9);

		const cb = this.getCode(b);
		const B = this.getRGB(cb, 0.4);

		//console.log(a, ca, ': ', A, '     ', b, cb, ': ', B);

		const grad = `linear-gradient(${degrees}deg, rgba(${A.r}, ${A.g}, ${A.b}, ${A.a}), rgba(${B.r}, ${B.g}, ${B.b}, ${B.a}) 67.71%)`;
		return grad;
	}

	getGradient2(a: string, degrees: number): string {
		const ca = this.getCode(a);
		const A = this.getRGB(ca, 1);
		//console.log(a, ca, ': ', A);

		const grad = `linear-gradient(${degrees}deg, rgba(255,255,255,0) 55%, rgba(${A.r}, ${A.g}, ${A.b}, ${A.a}) 100%)`;
		return grad;
	}

	toColor(c: RGB): string {
		return `rgba(${c.r}, ${c.g}, ${c.b}, ${c.a}`;
	}

	reverseString(str: string): string {
		return str
			.split('')
			.reverse()
			.join('');
	}

	merge(a: string, b: string): string {
		let s = ""
		for (let i = 0, l = Math.max(a.length, b.length); i < l; i++) {
			s += a.charAt(i) || '';
			s += b.charAt(i) || '';
		}
		return s;
	}

	// created () {}
	mounted() {
		const MAX = 3;
		const angle = 360 / MAX;

		const name = this.merge(this.firstName, this.reverseString(this.lastName))

		for (let i = 0; i < MAX; i++) {
			let a = String.fromCharCode(Math.floor((25 * i) / MAX) + 'a'.charCodeAt(0)) // default value if name is too short

			const degrees = angle * i;

			if (name.length >= MAX) 
				a = name[i];

			//const grad = this.getGradient(a, b, degrees)
			const grad = this.getGradient2(a, degrees);
			this.background += grad;
			if (i < MAX - 1) 
				this.background += ',\n';

			//test
			this.items.push(this.toColor(this.getRGB(i, 1)));
		}
	}
}
