export class Sound 
{
	audioSparkle = new Audio(`/audio/sparkle-01.mp3`)
	audioFinale  = new Audio(`/audio/finale-02.mp3`)
	
	playSparkle()
	{
		this.audioSparkle.play()
	}

	playFinale()
	{
		this.audioFinale.play()
	}
}