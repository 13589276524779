







































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'

@Component({
	components: {},
	mixins: [],
})
export default class LoadingSpinner extends Vue
{
	
}
