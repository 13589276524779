var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"dialog-background"},[_c('div',{staticClass:"dialog DlgGoal"},[_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.actionVerb)+" Goal")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"dialog-body"},[_c('div',{staticClass:"row cls"},[(_vm.showClass)?_c('validation-provider',{attrs:{"rules":"excluded:0","name":"Class"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Class","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-select',{attrs:{"placeholder":"Select a class","icon":"account","disabled":!_vm.addMode,"name":"class"},model:{value:(_vm.classId),callback:function ($$v) {_vm.classId=$$v},expression:"classId"}},_vm._l((_vm.Classes),function(c){return _c('option',{key:c.id,domProps:{"value":c.id}},[_vm._v(_vm._s(c.name))])}),0)],1)]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"row kid"},[_c('b-field',{attrs:{"label":"Kid"}},[_c('b-select',{staticClass:"select-kids",attrs:{"icon":"account","placeholder":"","disabled":!_vm.addMode,"name":"kid"},model:{value:(_vm.kidId),callback:function ($$v) {_vm.kidId=$$v},expression:"kidId"}},_vm._l((_vm.kids),function(k){return _c('option',{key:k.id,domProps:{"value":k.id}},[_vm._v(_vm._s(k.firstname)+" "+_vm._s(k.lastname))])}),0)],1)],1),_c('div',{staticClass:"row goal-name"},[_c('validation-provider',{attrs:{"rules":"required","name":"GoalName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Goal name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"maxlength":"50","name":"goal-name"},model:{value:(_vm.goalname),callback:function ($$v) {_vm.goalname=$$v},expression:"goalname"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('b-field',{attrs:{"label":"Goal details"}},[_c('b-input',{ref:"details",attrs:{"maxlength":"1000","type":"textarea","name":"goal-details"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1),_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"rules":{ integer: true, required: true, min_value: _vm.minAllowedStars, max_value: _vm.maxAllowedStars },"name":"Total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Total points","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"name":"total-points"},model:{value:(_vm.total),callback:function ($$v) {_vm.total=$$v},expression:"total"}})],1)]}}],null,true)})],1),(!_vm.addMode)?_c('div',{staticClass:"row hint"},[_vm._v("To edit other details, you can edit the cycle")]):_vm._e()]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"left"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[(_vm.addMode)?[_vm._v("Next "),_c('span',{staticStyle:{"padding-left":"4px"}},[_vm._v("❯❯")])]:[_vm._v("Save")]],2),_c('b-button',{attrs:{"type":"is-light"},on:{"click":_vm.close}},[_vm._v(" Cancel")])],1),_c('div',{staticClass:"right"},[(!_vm.addMode)?_c('b-button',{attrs:{"type":"is-danger"},on:{"click":_vm.deleteGoal}},[_vm._v("Delete")]):_vm._e()],1)])])]}}],null,false,1963876059)})],1),_c('DlgConfirm',{on:{"confirm":_vm.confirmDelete},scopedSlots:_vm._u([{key:"warning",fn:function(){return [_c('div',{staticClass:"warning"},[_vm._v("Warning: This action is permanent and not reversible. "),(_vm.classGoal)?[_vm._v("All cycles and steps for this goal will be deleted for all kids.")]:[_vm._v("All cycles and steps for this goal will be deleted.")]],2)]},proxy:true}],null,false,1485353274)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }