













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
	components: {},
	mixins: [],
})
export default class Logo extends Vue
{
	get logoPath(): string
		{
			return `/img/logo-${window.location.hostname}.png`
		}
}
