

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'

class Conf
{
	randomModifier = 0
	rand = (min: number, max: number) => Math.random() * (max - min) + min
	colorPair: any
	dimensions: any
	position: any
	clickPosition: any
	rotation: any
	scale: any
	velocity: any
	flipSpeed: any
	terminalVelocity: any
	color: any
	container: any

	colors = [
		{ front: '#3B870A', back: '#235106' },
		{ front: '#B96300', back: '#6f3b00' },
		{ front: '#E23D34', back: '#88251f' },
		{ front: '#CD3168', back: '#7b1d3e' },
		{ front: '#664E8B', back: '#3d2f53' },
		{ front: '#394F78', back: '#222f48' },
		{ front: '#008A8A', back: '#005353' },
	]

	// params to play with
	confettiParams = {
		// min and max size for each rectangle
		size: { x: [5, 20], y: [10, 18] },
		// power of explosion
		initSpeed: 25,
		// defines how fast particles go down after blast-off
		gravity: 0.65,
		// how wide is explosion
		drag: 0.08,
		// how slow particles are falling
		terminalVelocity: 6,
		// how fast particles are rotating around themselves
		flipSpeed: 0.017,
	};


	constructor (container: any, clickPosition: any)
	{
		this.container = container
		this.clickPosition = clickPosition

		this.randomModifier = this.rand(-1, 1)
		this.colorPair = this.colors[Math.floor(this.rand(0, this.colors.length))]
		this.dimensions = {
			x: this.rand(this.confettiParams.size.x[0], this.confettiParams.size.x[1]),
			y: this.rand(this.confettiParams.size.y[0], this.confettiParams.size.y[1]),
		}
		this.position = {
			x: this.clickPosition[0],
			y: this.clickPosition[1]
		}
		this.rotation = this.rand(0, 2 * Math.PI)
		this.scale = { x: 1, y: 1 }
		this.velocity = {
			x: this.rand(-this.confettiParams.initSpeed, this.confettiParams.initSpeed) * 0.4,
			y: this.rand(-this.confettiParams.initSpeed, this.confettiParams.initSpeed)
		}
		this.flipSpeed = this.rand(0.2, 1.5) * this.confettiParams.flipSpeed

		if (this.position.y <= this.container.h)
		{
			this.velocity.y = -Math.abs(this.velocity.y)
		}

		this.terminalVelocity = this.rand(1, 1.5) * this.confettiParams.terminalVelocity
	}


	update() 
	{
		this.velocity.x *= 0.98
		this.position.x += this.velocity.x

		this.velocity.y += (this.randomModifier * this.confettiParams.drag)
		this.velocity.y += this.confettiParams.gravity
		this.velocity.y = Math.min(this.velocity.y, this.terminalVelocity)
		this.position.y += this.velocity.y

		this.scale.y = Math.cos((this.position.y + this.randomModifier) * this.flipSpeed)
		this.color = this.scale.y > 0 ? this.colorPair.front : this.colorPair.back
	}
}

@Component({
	components: {},
	mixins: [],
})
export default class Confetti extends Vue
{
	confettiElements: any[] = []

	container: any
	animationFrameHandle = 0

	// number of confetti per "explosion"
	confettiCount = 111

	canvas: HTMLCanvasElement|null = null
	confettiCtx: CanvasRenderingContext2D|null = null

	async mounted()
	{
		this.canvas = this.$refs.confettiCanvas as HTMLCanvasElement
		this.confettiCtx = this.canvas!.getContext('2d')
	
		this.setupCanvas()
		this.updateConfetti()

		Evt.PopConfetti.On(this.addConfetti)
	}

	beforeDestroy()
	{
		Evt.PopConfetti.Off(this.addConfetti)
	}


	updateConfetti()
	{
		this.confettiCtx!.clearRect(0, 0, this.container.w, this.container.h)

		this.confettiElements.forEach((c) =>
		{
			c.update()
			this.confettiCtx!.translate(c.position.x, c.position.y)
			this.confettiCtx!.rotate(c.rotation)
			const width = (c.dimensions.x * c.scale.x)
			const height = (c.dimensions.y * c.scale.y)
			this.confettiCtx!.fillStyle = c.color
			this.confettiCtx!.fillRect(-0.5 * width, -0.5 * height, width, height)
			this.confettiCtx!.setTransform(1, 0, 0, 1, 0, 0)
		})

		this.confettiElements.forEach((c, idx) =>
		{
			if (c.position.y > this.container.h ||
				c.position.x < -0.5 * this.container.x ||
				c.position.x > 1.5 * this.container.x)
			{
				this.confettiElements.splice(idx, 1)
			}
		})
		this.animationFrameHandle = window.requestAnimationFrame(this.updateConfetti)
	}

	setupCanvas()
	{
		this.container = {
			w: this.canvas!.clientWidth,
			h: this.canvas!.clientHeight
		}
		this.canvas!.width = this.container.w
		this.canvas!.height = this.container.h
	}

	addConfetti(x:number = -1, y:number = -1)
	{
		let clickPosition: any
		const canvasBox = this.canvas!.getBoundingClientRect()
		if (x == -1 && y == -1) 
		{
			clickPosition = [
				canvasBox.width * Math.random(),
				canvasBox.height * Math.random()
			]
		} 
		else 
		{
			clickPosition = [
				x - canvasBox.left,
				y - canvasBox.top
			]
		}
		for (let i = 0; i < this.confettiCount; i++)
		{
			this.confettiElements.push(new Conf(this.container, clickPosition))
		}
	}

	hideConfetti()
	{
		this.confettiElements = []
		window.cancelAnimationFrame(this.animationFrameHandle)
	}


}
