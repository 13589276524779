
















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt, { DlgCycleMode } from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'
import { GoalKidsDTO } from '../shared/GoalKids.dto'
import { User } from '@/DTO/ClassKids.dto'
import { Class } from '@/DTO/ClassKids.dto'
import { Goal, Kidgoal } from '../shared/Goal.dto'
import DlgConfirm from '@/components/DlgConfirm.vue'

@Component({
	components: { DlgConfirm },
	mixins: [],
})
export default class DlgGoal extends Dialog
{
	goalname = ""
	details = ""
	total:number|null = null	// needs to allow null for validation
	classId:number = 0
	kidId = 0
	goalId = 0

	kids:User[] = []
	classGoals = new GoalKidsDTO()
	maxAchieved = 0
	classGoal = false	

	get maxAllowedStars(): number
	{
		return 1000
	}

	get minAllowedStars(): number
	{
		return this.maxAchieved < 1 ? 1 : this.maxAchieved
	}
	
	get Classes() 
	{
		return Store.Classes
	}

	get showClass()
	{
		return Store.LoggedInUser!.permissions.classes
	}

	@Watch('kidId')
	onKidChanged(n: number, o: number)
	{
		this.classGoal = n == 0
	}

	mounted()
	{
		Evt.EditGoal.On(this.ShowEdit)
		Evt.AddGoal.On(this.ShowAdd)
	}

	beforeDestroy()
	{
		Evt.EditGoal.Off(this.ShowEdit)
		Evt.AddGoal.Off(this.ShowAdd)		
	}

	async ShowAdd(classId: number, kidId = 0)	// Add mode
	{
		this.reset()
		this.visible = true
		this.addMode = true
	
		this.classId = classId
		this.kidId = kidId

		this.maxAchieved = 0
		
		await Store.GetClasses()
		this.refreshKidList()
	}

	async ShowEdit(classId:number, kidId:number, goalId:number, kidGoalId = 0)	// edit mode
	{
		this.reset()
		this.visible = true
		this.addMode = false
		
		this.classId   = classId
		this.kidId     = kidId
		this.goalId    = goalId

		try {
			const url = `${Store.BaseURL}/api/goal/${this.kidId}/${this.goalId}`
			const response = await axios.get(url, {withCredentials: true})
			const goal = response.data[0]

			this.goalname      = goal.name
			this.details       = goal.details
			this.total         = goal.total
			this.classGoal     = goal.classGoal

			if(this.classGoal)
				this.kidId = 0

			this.maxAchieved   = goal.kidgoals[0].achieved 	// This may allow achieved to go over total if other cycles have higher achievements, we'll allow it for now
		}
		catch (ex) { this.$handleError(ex, "Couldn't load goal") }
	
		await Store.GetClasses()
		this.refreshKidList()

		if(this.classGoal)
		{
			try {
				const url = `${Store.BaseURL}/api/kidsByClassGoal/${this.classId}/${this.goalId}`
				const response = await axios.get(url, {withCredentials: true});
				this.classGoals = response.data
				this.maxAchieved = this.classGoals.kidgoals.reduce( (a, v) => Math.max (a, v.achieved), 0 )
			} 
			catch (ex) 
			{
				this.$handleError(ex, "Couldn't load goal")
			}
		}
	}

	@Watch('classId')
	onClassIdChanged(n: string, o: string) 
	{
		this.refreshKidList()
	}

	refreshKidList()
	{
		const wholeClass:User = {id: 0, username:"", firstname:"Whole class", lastname: ""}
		const allKids:User = {id: 0, username:"", firstname:"All kids", lastname: ""}

		if(this.showClass)
			this.kids = [wholeClass]
		else
			this.kids = [allKids]

		const cls = Store.Classes.find( (o:Class) => o.id == this.classId) as unknown as Class
		if(cls)
			this.kids.push(...cls.users)	//use push with spread, instead of concat, as concat gets an observer instead of actual values	
	}

	close()
	{
		this.visible = false
	}

	async submit()
	{
		let goal = new Goal()
		goal.id        = this.goalId
		goal.classGoal = (this.kidId == 0)
		goal.classId   = this.classId
		goal.details   = this.details
		goal.kidId     = this.kidId
		goal.name      = this.goalname
		goal.total     = this.total!

		let kidGoal = new Kidgoal()
		kidGoal.clsId = this.classId
		kidGoal.usrId = this.kidId


		try 
		{
			let msg = 'Successfully added goal'

			if(this.addMode)
			{
				this.close()
				Evt.Show_DlgCycle.Emit( DlgCycleMode.AddGoal, kidGoal, goal)
			}
			else
			{
				const data = {
									id: this.goalId,
									classGoal: (this.kidId == 0),
									classId  : this.classId,
									details  : this.details,
									kidId    : this.kidId,
									name     : this.goalname,
									total    : this.total,
								}				
				await axios.put( '/api/goal', data, { withCredentials: true }	);
				msg = 'Successfully updated goal'

				this.$buefy.toast.open({
					message: msg,
					type: 'is-success',
				})

				this.close()
			
				Evt.ClassGoals_Refresh.Emit()
				Evt.GoalView_Refresh.Emit()
				Evt.ClassGoalView_Refresh.Emit()
				Evt.BarGoalsRefresh.Emit()				
			}
		} 
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	deleteGoal()
	{
		Evt.Show_DlgConfirm.Emit("goal", "Delete")
	}

	async confirmDelete()
	{
		try
		{
			const url = '/api/goal/' + this.goalId
			const response = await axios.delete(url, {withCredentials: true})
		} 
		catch (ex) { this.$handleError(ex, 'Error') }
		this.close()
		
		Evt.BarGoalsRefresh.Emit()
		Evt.GoalView_Refresh.Emit()

		this.$buefy.notification.open({
				message: `Goal successfully deleted`,
				position: 'is-top',
				type: 'is-success',
			})		
	}

	reset()
	{
		this.goalname = ""
		this.details = ""
		this.total = null

		this.classId = 0
		this.kidId = 0
		this.goalId = 0
	}
}

