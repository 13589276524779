import Vue from 'vue'
import { Goal, Kidgoal } from '../shared/Goal.dto'

const bus = new Vue()

export enum DlgUserMode
{
	Add = 1,
	Edit = 2,
	Profile = 3
}

export enum DlgCycleMode
{
	AddGoal = 1,
	AddCycle = 2,
	EditCycle = 3,
}
export default class Evt
{
	static EvtBase = class
	{
		protected static eventName = ""	// Used this.name to get the class name, but this broke when target in tsconfig was set to ES2015, it works with ES5, ane ES2016 and up

		// eslint-disable-next-line
		static On(method: (...args: any[]) => void)
		{
			bus.$on(this.eventName, method)
		}

		// eslint-disable-next-line
		static Once(method: (...args: any[]) => void)
		{
			bus.$once(this.eventName, method)
		}

		// eslint-disable-next-line
		static Off(method: (...args: any[]) => void)
		{
			bus.$off(this.eventName, method)
		}
	}

	static Show_DlgReferrals = class extends Evt.EvtBase
	{
		static eventName = 'Show_DlgReferrals'
		static Emit()
		{
			bus.$emit(this.eventName,  ...arguments)
			console.log(this.eventName, ...arguments)
		}
	}

	static CycleSaved = class extends Evt.EvtBase
	{
		static eventName = 'CycleSaved'
		static Emit(cycle: Kidgoal, mode: DlgCycleMode)
		{
			bus.$emit(this.eventName,  ...arguments)
			console.log(this.eventName, ...arguments)
		}
	}

	static Show_DlgCycle = class extends Evt.EvtBase
	{
		static eventName = 'Show_DlgCycle'
		static Emit( mode: DlgCycleMode, kidgoal: Kidgoal, goal: Goal)
		{
			bus.$emit(this.eventName,  ...arguments)
			console.log(this.eventName, ...arguments)
		}
	}

	static ShowTooltip = class extends Evt.EvtBase
	{
		protected static eventName = 'ShowTooltip'
		static Emit(name: string, selector = '')
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static HideTooltip = class extends Evt.EvtBase
	{
		protected static eventName = 'HideTooltip'
		static Emit(name: string = '', dontShowAgain = false)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static PopConfetti = class extends Evt.EvtBase
	{
		protected static eventName = 'PopConfetti'
		static Emit(x = -1, y = -1)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static App_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "App_Refresh"	// this.name doesn't work for some reason
		static Emit(key: string)
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static Users_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "Users_Refresh"	// this.name doesn't work for some reason
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static Show_DialogParents = class extends Evt.EvtBase
	{
		protected static eventName = "Show_DialogParents"	// this.name doesn't work for some reason
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static Show_DlgFeedback = class extends Evt.EvtBase
	{
		protected static eventName = "Show_DlgFeedback"	// this.name doesn't work for some reason
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static Show_DlgConfirm = class extends Evt.EvtBase
	{
		protected static eventName = "Show_DlgConfirm"	// this.name doesn't work for some reason
		static Emit(objToArchive: string, action="archive")
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static ShowDlgUser = class extends Evt.EvtBase
	{
		protected static eventName = "ShowDlgUser"	// this.name doesn't work for some reason
		static Emit(mode: DlgUserMode, userId: number, clsId: number,)
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}

	static Show_DlgCancelSubscription = class extends Evt.EvtBase
	{
		protected static eventName = "Show_DlgCancelSubscription"
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)
			
		}
	}	

	static AddGoal = class extends Evt.EvtBase
	{
		protected static eventName = "AddGoal"
		static Emit(classId: number, kidId: number)
		{
			bus.$emit(this.eventName, classId, kidId)
			
		}
	}

	static ShowDlgClass = class extends Evt.EvtBase
	{
		protected static eventName = "ShowDlgClass"
		static Emit(addMode?: boolean, classId?: number)
		{
			bus.$emit(this.eventName, addMode, classId)
			
		}
	}

	static ShowDlgStep = class extends Evt.EvtBase
	{
		protected static eventName = "ShowDlgStep"
		static Emit(kidGoalId: number, achieved: number, total: number)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static ClassGoalView_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = 'ClassGoalView_Refresh'
		static Emit()
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static EditGoal = class extends Evt.EvtBase
	{
		protected static eventName = 'EditGoal'
		static Emit(classId: number, kidId: number, goalId: number, kidGoalId = 0)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}
	
	static BarKidGoals_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "BarKidGoals_Refresh"
		static Emit()
		{
			bus.$emit(this.eventName)
			
		}
	}

	static BarGoalsRefresh = class extends Evt.EvtBase
	{
		protected static eventName = "BarGoalsRefresh"
		static Emit()
		{
			bus.$emit(this.eventName)
			console.log(this.eventName, ...arguments)
		}
	}

	static BarGoalsSet = class extends Evt.EvtBase
	{
		protected static eventName = "BarGoalsSet"
		static Emit(classId: number, kidId: number)
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}

	static GoalView_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "GoalView_Refresh"
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}

	static GoalView_Set = class extends Evt.EvtBase
	{
		protected static eventName = "GoalView_Set"
		static Emit(kidId: number, goalId: number)
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}
	

	static ClassGoals_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "ClassGoals_Refresh"
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}

	static BarClassKid_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "BarClassKid_Refresh"
		static Emit(classId = 0, kidId = 0)
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}

	static BarParent_Refresh = class extends Evt.EvtBase
	{
		protected static eventName = "BarParent_Refresh"
		static Emit()
		{
			bus.$emit(this.eventName, ...arguments)	// no need to repeate all the parameters, not sure if this is the best approach
			
		}
	}	

	static UnselectClassKid = class extends Evt.EvtBase
	{
		protected static eventName = 'UnselectClassKid'
		static Emit()
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static Show_DigInviteLink = class extends Evt.EvtBase
	{
		protected static eventName = 'Show_DigInviteLink'
		static Emit(key: string)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}

	static Show_BarClassKid = class extends Evt.EvtBase
	{
		static eventName = 'Show_BarClassKid'
		static Emit(visisble: boolean)
		{
			bus.$emit(this.eventName,  ...arguments)
			console.log(this.eventName,  ...arguments)
		}
	}

	static Show_BarParent = class extends Evt.EvtBase
	{
		static eventName = 'Show_BarParent'
		static Emit(visisble: boolean)
		{
			bus.$emit(this.eventName,  ...arguments)
		}
	}

	static Show_Bar = class extends Evt.EvtBase
	{
		static eventName = 'Show_BarClassKid'
		static Emit(visisble: boolean)
		{
			bus.$emit(this.eventName,  ...arguments)
		}
	}	

	static Show_BarGoal = class extends Evt.EvtBase
	{
		static eventName = 'Show_BarGoal'
		static Emit(visisble: boolean)
		{
			bus.$emit(this.eventName,  ...arguments)
			
		}
	}	

	static Show_BarKidGoals = class extends Evt.EvtBase
	{
		static eventName = 'Show_BarKidGoals'
		static Emit(visible: boolean)
		{
			bus.$emit(this.eventName,  ...arguments)
			console.log(this.eventName, ...arguments)
		}
	}
}

