import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Store } from '@/store'

import Buefy from 'buefy';
//import { Table, Input, Button, Field, Select, Icon, Radio, Autocomplete, Toast, Notification, Menu, Dropdown, Taginput, Checkbox} from 'buefy'
//import 'buefy/dist/buefy.css';	// this was preventing customizing variables in app.vue
import '@mdi/font/css/materialdesignicons.css'
import Trend from "vuetrend"
import { Vue2Storage } from 'vue2-storage'


Vue.use(Vue2Storage)
// You can pass options
Vue.use(Vue2Storage, {
	prefix: 'app_',
	driver: 'local',
	ttl: 60 * 60 * 24 * 30 * 1000 //30 hours
})

// import socketio from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'
// export const SocketInstance = socketio('http://localhost:3000');
// Vue.use(VueSocketIO, SocketInstance)

Vue.use(Trend)

Vue.config.productionTip = false;
Vue.use(Buefy,
	{
		defaultIconPack: 'mdi',
		defaultNoticeQueue: false,
	}
);

// Vue.use(Table)
// Vue.use(Input)
// Vue.use(Button)
// Vue.use(Field)
// Vue.use(Autocomplete)
// Vue.use(Select)
// Vue.use(Icon)
// Vue.use(Radio)
// Vue.use(Toast)
// Vue.use(Notification)
// Vue.use(Menu)
// Vue.use(Dropdown)
// Vue.use(Taginput)
// Vue.use(Checkbox)

//const baseUrl = store.state.BaseURL
Vue.mixin({
	methods: 
	{
		$handleError: async function (ex, msg, type = 'is-danger')
		{
			if(!msg)
				msg = "Error"
				
			console.error(ex)
			if (ex.response?.data?.statusCode == 401)
				Store.Logout(true)
			else 
			{
				this.$buefy.toast.open({
					message: msg,
					type: type,
				})
			}
		},
		$screen: function()
		{
			return {
				scrnHt: screen.height,
				scrnWd: screen.width,
				vpHt: window.innerHeight,
				vpWd: window.innerWidth,
				pxRt: window.devicePixelRatio,
			}
		},
		$isMobileView: function()
		{
			return (window.innerWidth <= 768)	//TODO: use variable
		},
		$signupVisible: function()
		{
			return window.location.hostname.includes('kidrewards.org') || window.location.hostname.includes('localhost')
		},
	}
})


new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
