






































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Store } from '@/store'
import Evt from '@/lib/Evt'
import axios from 'axios'
import Dialog from '@/components/Dialog.vue'

@Component({
	components: {},
	mixins: [],
})
export default class DlgResetPassword extends Dialog
{
	username = null
	password = ""
	confirmation = ""
	token = ''

	async mounted()
	{
		if(this.open)
		{
			this.token = this.$route.query.token.toString()
			this.checkToken()	
		}
	}

	protected async checkToken()
	{
		try
		{
			const url = "/user/check-reset-password/" + this.token
			const response = await axios.get(url, { withCredentials: true })
			if(response.data.success && response.data.o)
				this.username = response.data.o.username
			else
			{
				this.problem = response.data.message
			}
		}
		catch (ex) { this.$handleError(ex, ex.response.data.message) }
	}

	public Display()
	{
		this.visible = true
	}

	protected close()
	{
		this.visible = false
	}

	protected async submit()
	{
		try
		{
			const url = '/user/reset-password'
			const response = await axios.post(url,
				{
					resetToken: this.token,
					password: this.password,
				},
				{ withCredentials: true })

			this.$buefy.notification.open({
				message: `Password reset successfully`,
				position: 'is-top',
				type: 'is-success',
			})

			this.close()
			this.$router.push({ name: 'Start', query: { action: 'login' } })
		}
		catch (ex) { this.$handleError(ex, ex.response.data.message) }

	}

}
