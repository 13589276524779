



































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import GoalGraph from '@/components/GoalGraph.vue'
import Empty from '@/components/Empty.vue'
import Evt, { DlgUserMode } from '@/lib/Evt'
import router from '@/router'
import { Store } from '@/store'


// Component.registerHooks([
// 	//'beforeRouteEnter',
// 	//'beforeRouteLeave',
// 	//'beforeRouteUpdate'
// ])

@Component({
	components: {  GoalGraph, Empty },
	mixins: []
})
export default class BarGoals extends Vue 
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number;
	@Prop({ type: Number, required: false, default: 0 }) readonly kidId!: number;	
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number;	

	@Prop({ type: Boolean, required: false, default: true }) readonly low!: boolean;	// low is false when logging in ClassParent, true otherwise

	store = Store
	goals = []
	left = '-220px'	//TODO: use variable
	visible = false
	visibleLeft = '220px'

	isActive = true
	async mounted()
	{
		Evt.Show_BarGoal.On(this.show)
		Evt.BarGoalsRefresh.On(this.refresh)

		this.visible = !this.$isMobileView()
		
		if(this.clsId && this.kidId)
			this.visible = true

		if(window.innerWidth < 440)	//TODO use variables
			this.visibleLeft = 'calc(100vw - 220px)'		//TODO use variables

		this.show(this.visible)

		this.refresh()
	}

	beforeDestroy()
	{
		Evt.Show_BarGoal.Off(this.show)
		Evt.BarGoalsRefresh.Off(this.refresh)

		Evt.HideTooltip.Emit('tip-add-goal')
	}

	// beforeRouteUpdate(to, from, next)
	// {
	// 	console.log(to, from)
	// 	next()
	// }

	get emptyMessage()
	{
		if(!this.kidId || !this.clsId)
			return ""
		else 
			return "No goals yet"
	}

	get canGoal()
	{
		return Store.LoggedInUser!.permissions.goals
	}

	get editMenuVisible()
	{
		return Store.LoggedInUser!.permissions.classes && Store.LoggedInUser!.permissions.kids
	}

	get canKid()
	{
		return Store.LoggedInUser!.permissions.kids
	}

	get canClass()
	{
		return Store.LoggedInUser!.permissions.classes
	}

	@Watch('kidId')
	onKidChange(n: string, o: string) 
	{
		console.log("BarGoals.refresh kidChange")
		this.refresh()
	}

	@Watch('clsId')
	onClsChange(n: string, o: string) 
	{
		console.log("BarGoals.refresh clsChange")
		this.refresh()
	}

	show(visible: boolean)
	{
		this.visible = visible

		if(visible)
		{
			this.left = this.visibleLeft
		}
		else
			this.left = '-220px'	//TODO use variables
	}

	async refresh()
	{
		Evt.HideTooltip.Emit('tip-add-goal')
		if(!this.kidId || !this.clsId)
		{
			this.goals = []
			return
		}

		console.log('refreshGoals: ', this.clsId, this.kidId)
		const url = `${Store.BaseURL}/api/goals/${this.clsId}/${this.kidId}`

		try {
			const response = await axios.get(url, {withCredentials: true})
			this.goals = response.data

			if(this.goals.length == 0 && Store.Classes.length == 1 && Store.Classes[0].users.length == 1)
				Evt.ShowTooltip.Emit('tip-add-goal', '.add-goal')
			
			Store.SetApiDeployTime(response.headers["x-deployed"])
			//console.log("deployTime from header:", response.headers["x-deployed"])
		} 
		catch (ex) 
		{
			this.$handleError(ex, "Couldn't load goals")
		}
	}

	editKid()
	{
		Evt.ShowDlgUser.Emit( DlgUserMode.Edit, this.kidId, 0, )
	}

	editClass()
	{
		Evt.ShowDlgClass.Emit(false, this.clsId)
	}

	addGoal()
	{
		Evt.AddGoal.Emit(this.clsId, this.kidId)
		Evt.HideTooltip.Emit('tip-add-goal', true)	// donn't show add-goal tip once a goal has been added
	}

	openGoal(goalId:number)
	{
		if( this.$isMobileView() )
		{
			Evt.Show_BarGoal.Emit(false)
			Evt.Show_BarClassKid.Emit(false)
			Evt.Show_BarParent.Emit(false)
		}

		console.log('openGoal: ', goalId)
		router.push( {	name: this.$route.name!,
						params:{
									clsId:  this.clsId.toString(),
									kidId:  this.kidId.toString(),
									goalId: goalId.toString(),
									kidGoalId: "0"
								}}
								,() => {})
	}

	

}
