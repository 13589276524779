





























import { Vue, Component, Prop } from 'vue-property-decorator'

import Evt from '@/lib/Evt'
import axios from 'axios'

import { Store } from '@/store'
import router from '@/router'

import {Cls} from '@/DTO/ClassGoals.dto.ts'

@Component({
	components: {},
	props: {},
	mixins: [],
	
})
export default class SubBarClassGoals extends Vue
{
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number;
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number;	
	
	protected data:Cls[] = []
	protected selectedGoalId = 0

	async mounted()
	{
		await this.refresh()
		Evt.ClassGoals_Refresh.On(this.refresh)
		if(this.clsId)
		{
			const cls = this.data.find(i => i.id == this.clsId)
			this.selectClass(cls)
		}
	}

	beforeDestroy()
	{
		Evt.ClassGoals_Refresh.Off(this.refresh)
	}


	async refresh()
	{
		const url = Store.BaseURL + '/api/classGoals'
		try {
			const response = await axios.get(url, {withCredentials: true});
			this.data = response.data
		} 
		catch (ex)
		{
			this.$handleError(ex, "Couldn't load class goals")
		}		
	}

	protected selectClass(cls:any)
	{
		Store.SetSelectedClass(cls)
		this.selectedGoalId = 0
		
		console.log(cls)
	}	

	protected selectGoal(goalId: number, start: string)
	{
		if( this.$isMobileView() )
		{
			Evt.Show_BarClassKid.Emit(false)
			Evt.Show_BarParent.Emit(false)
		}
		
		router.push({name: 'goals', 
				params:{ clsId: Store.SelectedClass.id.toString(), goalId:goalId.toString(), start: start},
			},() => {})
		
		Vue.nextTick(() => {
			Evt.ClassGoalView_Refresh.Emit()
		})
	}
}
