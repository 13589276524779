

















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
//import {mapState} from 'vuex'

import axios from 'axios'
import Evt from '@/lib/Evt'
import NavBar from '@/components/NavBar.vue'
import BarClassKid from '@/components/BarClassKid.vue'
import BarGoals from '@/components/BarGoals.vue'
import BarParent from '@/components/BarParent.vue'

import DlgUser	 from '@/components/DlgUser.vue'
import DlgClass from '@/components/DlgClass.vue'
import DlgGoal from '@/components/DlgGoal.vue'
import DlgStep from '@/components/DlgStep.vue'
import DlgFeedback from '@/components/DlgFeedback.vue'
import DlgCycle from '@/components/DlgCycle.vue'

import GoalView from '@/components/GoalView.vue'
import ClassGoalView from '@/components/ClassGoalView.vue'

import {Tab} from '@/lib/Tab'
import { Store } from '@/store'
import { Role } from '../shared/Permissions'
import { StripeCheckout } from '@/lib/StripeCheckout'
import { DateTime } from 'luxon'
import router from '@/router'
import Confetti from '@/components/Confetti.vue'
import ToolTip from '@/components/ToolTip.vue'
import DlgInviteLink from '@/components/DlgInviteLink.vue'
import DlgReferrals from '@/components/DlgReferrals.vue'
import User from '@/lib/User.model'

//import io from 'socket.io-client'

@Component({
	components: {NavBar,
				BarClassKid,
				BarGoals,
				BarParent,
				ClassGoalView,
				GoalView,
				DlgUser,
				DlgClass,
				DlgGoal,
				DlgStep,
				DlgFeedback,
				Confetti,
				ToolTip,
				DlgInviteLink,
				DlgCycle,
				DlgReferrals
				},
	mixins: [],
//	computed: mapState([ 'BaseURL', 'LoggedInUser' ])
})
export default class Kids extends Vue
{
	@Prop({ type: String, required: false, default: Tab.kids }) readonly tab!: string
	@Prop({ type: Number, required: false, default: 0 }) readonly clsId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidId!: number	
	@Prop({ type: Number, required: false, default: 0 }) readonly goalId!: number
	@Prop({ type: Number, required: false, default: 0 }) readonly kidGoalId!: number	//cycle
	@Prop({ type: String, required: false             }) readonly start!: string

	get Tab():typeof Tab {return Tab}	// make this accessible in the template
	socket = null
	showTooltips = true

	Evt = Evt	// so navbar can accesss it

	get barEvent():Evt
	{
		if(Store.LoggedInUser!.role == Role.parentClass)
			return Evt.Show_BarParent
		else 
			return Evt.Show_BarClassKid
	}

	get trialEndsVisible()
	{
		return (
			Store.LoggedInUser!.permissions.subscribe &&
			Store.LoggedInUser!.school!.trialEnd
		)
	}

	get numTrialDaysLeft()
	{
		const dateString = Store.LoggedInUser!.school!.trialEnd
		if(!dateString)
			return 0

		const dtTrialEnd = DateTime.fromISO( dateString )
		return Math.ceil(dtTrialEnd.diffNow("days").days)
	}

	async mounted()
	{
		if(!Store.LoggedInUser)
			Store.Logout(true)

		if(Store.LoggedInUser!.role == Role.kid)	//TODO: This causes an endless loop fix it
			this.$router.push({name: 'kid'})

		if(!Store.LoggedInUser!.school!.active)
			this.$router.replace({ name:'TrialExpired' })

		Evt.ShowDlgClass.On(this.ShowDlgClass)

		if(this.$route.query.hints == 'false')
			this.showTooltips = false

		if(Store.LoggedInUser!.role == Role.kid || Store.LoggedInUser!.role == Role.parentClass)
			this.showTooltips = false

		if(this.$route.query.action == "subscribe-success")
		{
			this.$buefy.notification.open({
                    duration: 5000,
                    message: `Thank you for subscribing`,
                    position: 'is-top',
                    type: 'is-success',
				})
			
			try
			{
				const response = await axios.get('/user/me', {withCredentials : true} )
				let user:User = response.data.o

				if(user.school?.trialEnd)	// This should be null if the stripe webhook was called succcessfully, let's get and set the subscription data
				{
					try {
						const url='/signup/set-subscription/' + user.school.sessionId
						const response = await axios.post(url,
							{
							},
							{withCredentials: true});
					} 
					catch (ex) { this.$handleError(ex, ex.response.data.message) }

					const response = await axios.get('/user/me', {withCredentials : true} )
					user = response.data.o
				}

				Store.SetUser(user)
			}
			catch(ex){ this.$handleError(ex, "Error getting user/school info") }
		}
	}

	beforeDestroy()
	{
		Evt.ShowDlgClass.Off(this.ShowDlgClass)
	}

	@Watch('tab')
	onTabChange(n: string, o: string) 
	{
		//console.log("tab changed")
	}

	ShowDlgClass(addMode:boolean, classId:number)
	{
		const dlg = this.$refs.dlgClass as DlgClass
		dlg.Show(true, addMode, classId)
	}

	subscribe()
	{
		const school = Store.LoggedInUser!.school!
		StripeCheckout.Checkout( school.id!, school.plan!, Store.StripePubKey)
	}

	userArchived()
	{
		router.push({name: 'kids', params:{clsId:this.clsId.toString()}},() => {})
		Store.SetSelectedKidName('')
	}

	showFeedbackDlg()
	{
		Evt.Show_DlgFeedback.Emit()
	}
}
